import { CustomAuth } from '../models/Entities';
import { AuthorityType } from '../models/Types';

class RolesService {
    /**
     * Returns if the authenticated user has all the roles.
     * @param auth the auth
     * @param roles the roles
     * @returns if the authenticated user has all the roles
     */
    hasAllRoles = (auth: CustomAuth | undefined, roles: AuthorityType[]): boolean => {
        return !!auth && roles.every((r) => auth.authorities.includes(r));
    };

    /**
     * Returns if the authenticated user has any the roles.
     * @param auth the auth
     * @param roles the roles
     * @returns if the authenticated user any all the roles
     */
    hasAnyRole = (auth: CustomAuth | undefined, roles: AuthorityType[]): boolean => {
        return !!auth && roles.some((r) => auth.authorities.includes(r));
    };
}

const rolesService: RolesService = new RolesService();
export default rolesService;
