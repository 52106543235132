import React from 'react';

/**
 * Returns the error page.
 * @returns the error page.
 */
const ErrorPage: React.FC = () => {
    return <>ErrorPage</>;
};
export default ErrorPage;
