import Icon, { PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Collapse, List, Tabs, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import courseClient from '../../clients/CourseClient';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import { Course } from '../../models/Entities';
import avatarImage from '../../resources/images/avatar-example1.svg';
import { ReactComponent as dotsIcon } from '../../resources/images/dots-white-icon.svg';
import { ReactComponent as dotsBlackIcon } from '../../resources/images/dots-black-icon.svg';
import { ReactComponent as filterIcon } from '../../resources/images/filter-icon.svg';
import { ReactComponent as downloadIcon } from '../../resources/images/download-green-icon.svg';
import { ReactComponent as folderIcon } from '../../resources/images/folder-icon.svg';
import { ReactComponent as pencilIcon } from '../../resources/images/pencil-icon.svg';
import { ReactComponent as downArrowIcon } from '../../resources/images/down-arrow-icon.svg';
import { ReactComponent as playFilledIcon } from '../../resources/images/play-filled-icon.svg';
import alertService from '../../services/AlertService';
import styles from './CourseInstructorPage.module.scss';
import { useInstructorCourse } from '../../hooks/useRhodes';

const { Panel } = Collapse;

/**
 * Returns course instructor page.
 * @returns course instructor page.
 */
const CourseInstructorPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const params = useParams<ParamsType>();
    //const [loading, setLoading] = useState<'loading'>();
    //const [course, setCourse] = useState<Course>();

    const {
        course,
        courseLoading: loading,
        courseError: error
    } = useInstructorCourse(params.id!);
/*
    // initialize app
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const course = await courseClient.getInstructorCourse(params.id!);
                setCourse(course);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, params.id]);
*/
    /*** METHODS ***/

    /*** VISUAL ***/

    const items = [
        {
            label: <FormattedMessage id="courseInstructor.students" />,
            key: 'students',
            children: course?.name && <StudentsComponent course={course} />
        },
        {
            label: <FormattedMessage id="courseInstructor.assignments" />,
            key: 'assignment',
            children: course?.name && <AssignmentsComponent course={course} />
        },
        {
            label: <FormattedMessage id="courseInstructor.curriculum" />,
            key: 'curriculum',
            children: course?.name && <CurriculumComponent course={course} />
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="courseInstructor.title" />}
            menu="courseInstructor"
            lilacHeader
            rightHeader={<Button icon={<Icon component={dotsIcon} className={styles.icon} />} type="ghost"></Button>}
            subHeader={<div className={styles.title}>{course?.name}</div>}
        >
            <Tabs items={items} className={styles.tabs} />
        </LayoutComponent>
    );
};
export default CourseInstructorPage;

type ParamsType = { id: string };

/**
 * Returns the students component.
 * @returns the students component
 */
const StudentsComponent = (props: any): React.ReactElement => {
    const { course } = props;
    const students = course.seats || [];

    const {
        completeSeat
    } = useInstructorCourse(course.id!);

    /*** HOOKS ***/

    /*** EFFECTS ***/

    /*** METHODS ***/

    /*** COMPONENTS */

    return (
        <>
            <div className={styles.filter}>
                <span className={styles.allStudents}>
                    <FormattedMessage id="courseInstructor.allStudents" values={{ students: students.length }} />
                </span>
                <Button icon={<Icon component={filterIcon} />} className={styles.button}>
                    <FormattedMessage id="button.filter" />
                </Button>
            </div>
            <List
                grid={{
                    column: 1
                }}
                dataSource={students}
                renderItem={(student: any) => {
                    return (
                        <List.Item key={student.seat}>
                            <div className={styles.student}>
                                <div className={styles.personal}>
                                    <Avatar src={avatarImage} className={styles.avatar} />
                                    <span className={styles.info}>
                                        <Link to="/course-student">
                                            <div className={styles.name}>{`Seat ${student.seat}`}</div>
                                            {!!student.owner && <div className={styles.name}>{`Owner ${student.owner}`}</div>}
                                            {!!student.registered && <div className={styles.name}>{`Student ${student.student} Registered`}</div>}
                                            {!!student.registered && !student.completed && <div className={styles.name}>{`In Progress`}</div>} 
                                            {!!student.completed && <div className={styles.name}>{`Course Completed`}</div>}
                                            {!!student.completed && <div className={styles.name}>{`Grade ${student.grade}`}</div>}
                                        </Link>
                                        {!!student.registered && !student.completed && 
                                        <Button icon={<Icon component={filterIcon} />} className={styles.button} onClick={() => completeSeat(student.course, student.seat, true, "A")}>
                                            Complete
                                        </Button>}
                                        <div className={styles.lastSeen}>Yesterday</div>
                                    </span>
                                </div>
                                <Link to="/assignments/1">
                                    <Tag className={styles.tag}>Assignment</Tag>
                                </Link>
                            </div>
                        </List.Item>
                    );
                }}
                className={styles.studentsList}
            />
        </>
    );
};

interface StudentsProps {
    course: Course;
}

/**
 * Returns the assignments component.
 * @returns the assignments component
 */
const AssignmentsComponent = (props: any): React.ReactElement => {
    const { course } = props;
    const assignments = course.assignments || [];

    /*** HOOKS ***/

    /*** EFFECTS ***/

    /*** METHODS ***/

    /*** COMPONENTS */

    return (
        <>
            <div className={styles.filter}>
                <span className={styles.allStudents}>
                    <FormattedMessage id="courseInstructor.allAssignments" values={{ assignments: assignments.length }} />
                </span>
                <Link to="/assignment-creation">
                    <Button icon={<PlusOutlined />} type="ghost"></Button>
                </Link>
            </div>
            <List
                grid={{
                    column: 1
                }}
                dataSource={assignments}
                renderItem={(assignment: any) => {
                    return (
                        <List.Item key={assignment.id}>
                            <Card
                                title={
                                    <div className={styles.header}>
                                        <div>
                                            <div className={styles.name}>{assignment.title}</div>
                                            <div className={styles.description}>{assignment.description}</div>
                                        </div>
                                        <Button icon={<Icon component={dotsBlackIcon} className={`${styles.icon} ${styles.dots}`} />} type="ghost"></Button>
                                    </div>
                                }
                                className={styles.card}
                            >
                                <div className={styles.content}>
                                    <div className={styles.file}>
                                        <Icon component={folderIcon} className={styles.icon} />
                                        <span className={styles.fileName}>{assignment.file}</span>
                                    </div>
                                    <Button icon={<Icon component={downloadIcon} className={styles.download} />} type="ghost"></Button>
                                </div>
                            </Card>
                        </List.Item>
                    );
                }}
                className={styles.assignmentsList}
            />
        </>
    );
};

interface AssignmentsProps {
    course: Course;
}

/**
 * Returns the curriculum component.
 * @returns the curriculum component
 */
const CurriculumComponent = (props: any): React.ReactElement => {
    const { course } = props;
    const lessons = course.lessons || [];

    /*** HOOKS ***/

    /*** EFFECTS ***/

    /*** METHODS ***/

    /*** COMPONENTS */

    return (
        <>
            <div className={styles.filterCurriculum}>
                <Button icon={<Icon component={pencilIcon} className={styles.pencil} />} type="ghost"></Button>
            </div>
            {lessons &&
                lessons.map((lesson: any) => {
                    return (
                        <Collapse className={styles.curriculum} defaultActiveKey={lessons[0].id}>
                            <Panel
                                header={
                                    <div className={styles.header}>
                                        <span className={styles.lesson}>{lesson.title}</span>
                                        <Icon component={downArrowIcon} className={styles.arrowDown} />
                                    </div>
                                }
                                key={lesson.id}
                            >
                                {lesson.videos?.map((video: any) => {
                                    return (
                                        <div className={styles.content}>
                                            <div className={styles.playName}>
                                                <Icon component={playFilledIcon} className={styles.play} />
                                                <span className={styles.name}>{video.name}</span>
                                            </div>
                                            <div className={styles.duration}>{video.duration}</div>
                                        </div>
                                    );
                                })}
                            </Panel>
                        </Collapse>
                    );
                })}
        </>
    );
};

interface CurriculumProps {
    course: Course;
}
