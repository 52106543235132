import Icon, { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import { ReactComponent as dotsIcon } from '../../resources/images/dots-black-icon.svg';
import styles from './AssignmentCreationPage.module.scss';

/**
 * Returns assignment creation page.
 * @returns assignment creation page.
 */
const AssignmentCreationPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<'loading'>();

    /*** METHODS ***/

    /*** VISUAL ***/

    return (
        <LayoutComponent
            title={<FormattedMessage id="assignmentCreation.title" />}
            menu="assignmentCreation"
            leftHeader={<Button icon={<ArrowLeftOutlined />} type="ghost" onClick={() => navigate(-1)}></Button>}
            centerHeader={
                <div className="pageTitle">
                    <FormattedMessage id="assignmentCreation.create" />
                </div>
            }
            rightHeader={<Button icon={<Icon component={dotsIcon} className={styles.icon} />} type="ghost"></Button>}
        >
            <div className={styles.title}>
                <FormattedMessage id="assignmentCreation.assignment" />
            </div>
            <div className={styles.description}>
                <FormattedMessage id="common.loremIpsum" />
            </div>
            <Form form={form} /* onFinish={save} */ colon={false} layout="vertical">
                <Form.Item name="lecture">
                    <Select placeholder={intl.formatMessage({ id: 'assignmentCreation.selectLecture' })} className={styles.select}>
                        {}
                    </Select>
                </Form.Item>
                <Form.Item name="title">
                    <Input placeholder={intl.formatMessage({ id: 'assignmentCreation.assignmentTitle' })} className={styles.textArea} />
                </Form.Item>
                <Form.Item name="description">
                    <TextArea placeholder={intl.formatMessage({ id: 'assignmentCreation.description' })} className={styles.textArea} draggable={false} />
                </Form.Item>
                <Form.Item name="date">
                    <DatePicker className={styles.date} />
                </Form.Item>
                <div className={styles.materials}>
                    <Button icon={<PlusOutlined />} type="ghost"></Button>
                    <div className={styles.text}>
                        <FormattedMessage id="assignmentCreation.materials" />
                    </div>
                </div>
                <div className={styles.buttons}>
                    <Button className={styles.cancel} onClick={() => form.resetFields()}>
                        <FormattedMessage id="button.cancel" />
                    </Button>
                    <Button type="primary" htmlType="submit" loading={loading === 'loading'} className="buttons">
                        <FormattedMessage id="assignmentCreation.create" />
                    </Button>
                </div>
            </Form>
        </LayoutComponent>
    );
};
export default AssignmentCreationPage;
