export const authorities = ['ROLE_ADMIN', 'ROLE_INSTRUCTOR', 'ROLE_STUDENT'] as const;
export type AuthorityType = typeof authorities[number];

export const menus = [
    'home',
    'student',
    'instructor',
    'myCourses',
    'credits',
    'courseActive',
    'courseInstructor',
    'assignment',
    'assignmentCreation',
    'courseStudent',
    'courseCreation',
    'settings',
    'courses',
    'course',
    'howItWorks',
    'faq',
    'help',
    'becomeInstructor',
    'instructorRequests',
    'adminInstructors',
    'adminStudents',
    'adminCourses'
] as const;
export type MenuType = typeof menus[number];

export const viewModes = ['INSTRUCTOR', 'STUDENT'] as const;
export type ViewModeType = typeof viewModes[number];

export const categories = ['Software development', 'Civil engineering', 'Business Writing', 'Leadership Skills'] as const;
export type CategoryType = typeof categories[number];

export const genders = ['Male', 'Female'] as const;
export type GenderType = typeof genders[number];

export const courseStatuses = ['Open', 'Closed', 'Complete', 'In-Progress', 'Not Started'] as const;
export type CourseStatusType = typeof courseStatuses[number];
