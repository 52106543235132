import { User } from '../models/Entities';

/**
 * This class represents the wallet client.
 */
class UserClient {
    /**
     * Retrieves the current user.
     * @returns the current user
     */
    getCurrent = async (): Promise<User> => {
        if (localStorage.getItem('currentUser')) {
            return JSON.parse(localStorage.getItem('currentUser') as string);
        }

        throw new Error();
    };

    create = async (user: User): Promise<User> => {
        localStorage.setItem('currentUser', JSON.stringify(user));
        return user;

        //throw new Error();
    };
}

const userClient: UserClient = new UserClient();
export default userClient;
