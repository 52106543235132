import Icon from '@ant-design/icons';
import { Avatar, Button, Drawer, Image, Layout, Menu } from 'antd';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { Footer } from 'antd/lib/layout/layout';
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import CustomContext from '../../contexts/CustomContext';
import { CustomAuth } from '../../models/Entities';
import { AuthorityType, MenuType } from '../../models/Types';
import { useWalletConnect } from '../../hooks/useRhodes';
import avatarExample from '../../resources/images/avatar-example1.svg';
import { ReactComponent as browseIcon } from '../../resources/images/browseCourses-menu-icon.svg';
import { ReactComponent as drawerIcon } from '../../resources/images/drawer-black-icon.svg';
import { ReactComponent as drawerCloseIcon } from '../../resources/images/drawer-close-icon.svg';
import { ReactComponent as drawerIconWhite } from '../../resources/images/drawer-icon.svg';
import { ReactComponent as faqIcon } from '../../resources/images/faq-menu-icon.svg';
import { ReactComponent as helpIcon } from '../../resources/images/help-menu-icon.svg';
import { ReactComponent as howIcon } from '../../resources/images/howItWorks-menu-icon.svg';
import { ReactComponent as logoutIcon } from '../../resources/images/logout-icon.svg';
import { ReactComponent as myCoursesIcon } from '../../resources/images/myCourses-menu-icon.svg';
import { ReactComponent as preferencesIcon } from '../../resources/images/preferences-menu-icon.svg';
import raLogo from '../../resources/images/ra-icon.svg';
import raLogoWhite from '../../resources/images/ra-white-icon.svg';
import rhodesAcademyLogo from '../../resources/images/rhodes-academy-icon.svg';
import rolesService from '../../services/RolesService';
import './LayoutComponent.css';
import styles from './LayoutComponent.module.scss';

const { Header, Sider, Content } = Layout;

const LayoutComponent: React.FC<Props> = (props) => {
    const { menu, title, leftHeader, centerHeader, rightHeader, children, subHeader, lilacHeader, lilacContent, isCredits } = props;
    /*** HOOKS***/

    const navigate = useNavigate();
    const context = useContext(CustomContext);
    const { auth } = context;
    const [collapsed, setCollapsed] = useState(localStorage.getItem('collapsed') !== null && localStorage.getItem('collapsed') === 'true');
    const { 
        isLoading,
        pendingConnector,
        connectError,
        isConnected,
        account,
        connect,
        disconnect
    } = useWalletConnect();

    /*** EFFECTS ***/

    useEffect(() => {
        if (localStorage.getItem('collapsed') !== null && localStorage.getItem('collapsed') === 'true') {
            setCollapsed(true);
        }
    }, []);

    /*** METHODS ***/

    // calculate screen size
    // const screens = useBreakpoint();
    // const desktop: boolean = Object.entries(screens)
    //     .filter((screen) => !!screen[1])
    //     .some((s) => ['lg', 'xl', 'xxl'].includes(s[0]));
    const desktop: boolean = false;

    /**
     * Collapses or expands the sidebar menu.
     * @param collapsed if the sidebar shuld be collapsed
     */
    const collapse = (collapsed: boolean) => {
        desktop ? localStorage.setItem('collapsed', collapsed.toString()) : localStorage.removeItem('collapsed');
        setCollapsed(collapsed);
    };

    // TODO Delete these methods when we are allowed to authenticate
    const signInAsAdmin = () => {
        let customAuth: CustomAuth | undefined;

        const authorities: AuthorityType[] = ['ROLE_ADMIN'];
        customAuth = {
            id: '1',
            email: 'example@email.com',
            name: 'Peter Evans',
            authorities,
            initialized: true
        };
        context.setAuth(customAuth);
        navigate('/instructor-requests');
    };

    const signInAsStudent = () => {
        let customAuth: CustomAuth | undefined;

        const authorities: AuthorityType[] = ['ROLE_STUDENT'];
        customAuth = {
            id: '1',
            email: 'example@email.com',
            name: 'Peter Evans',
            authorities,
            initialized: true,
            mode: 'STUDENT'
        };
        context.setAuth(customAuth);
        navigate('/student');
    };

    const signInAsInstructor = () => {
        let customAuth: CustomAuth | undefined;
        const authorities: AuthorityType[] = ['ROLE_STUDENT', 'ROLE_INSTRUCTOR'];
        customAuth = {
            id: '1',
            email: 'example1@email.com',
            name: 'Wade Williams',
            authorities,
            initialized: true,
            mode: 'INSTRUCTOR'
        };
        context.setAuth(customAuth);
        navigate('/instructor');
    };

    const switchToInstructor = () => {
        if (auth) {
            let customAuth: CustomAuth = auth;
            customAuth.mode = 'INSTRUCTOR';
            context.setAuth(customAuth);
            navigate('/instructor');
        }
    };

    const switchToStudent = () => {
        if (auth) {
            let customAuth: CustomAuth = auth;
            customAuth.mode = 'STUDENT';
            context.setAuth(customAuth);
            navigate('/student');
        }
    };

    /*** COMPONENTS ***/

    const siderStyles = collapsed ? `${styles.sider} ${styles.collapsed}` : styles.sider;

    if (desktop) {
        return (
            <Layout className={styles.layout}>
                <Sider collapsible collapsed={collapsed} onCollapse={(value) => collapse(value)} className={siderStyles} theme="light">
                    <div className={styles.title}>
                        <Link to="/">RHODES ACADEMY</Link>
                    </div>
                    {auth?.authorities.includes('ROLE_STUDENT') && !auth?.authorities.includes('ROLE_INSTRUCTOR') && (
                        <Link to="/become-instructor">
                            <Button>
                                <FormattedMessage id="layout.becomeInstructor" />
                            </Button>
                        </Link>
                    )}
                    {rolesService.hasAnyRole(auth, ['ROLE_INSTRUCTOR']) && auth?.mode === 'STUDENT' && (
                        <Button onClick={switchToInstructor}>
                            <FormattedMessage id="layout.switchStudent" />
                        </Button>
                    )}
                    {rolesService.hasAnyRole(auth, ['ROLE_INSTRUCTOR']) && auth?.mode === 'INSTRUCTOR' && (
                        <Button onClick={switchToStudent}>
                            <FormattedMessage id="layout.switchInstructor" />
                        </Button>
                    )}
                    <MenuComponent menu={menu} collapsed={collapsed} desktop={desktop} />
                </Sider>
                <Layout>
                    <Header className={styles.header}>
                        {title} {auth && JSON.stringify(auth)}
                        {!auth && (
                            <>
                                <Button onClick={signInAsAdmin}>Sign in as Admin</Button>
                                <Button onClick={signInAsStudent}>Sign in as Student</Button>
                                <Button onClick={signInAsInstructor}>Sign in as Instructor</Button>
                            </>
                        )}
                        {!auth && <Button onClick={connect}>Sign In</Button>}
                        {!!auth && <Button onClick={disconnect}>Sign Out</Button>}
                    </Header>
                    <Content className={styles.content}>
                        <div className={styles.main}>{children}</div>
                    </Content>
                    <Footer className={styles.footer}></Footer>
                </Layout>
            </Layout>
        );
    } else {
        return (
            <Layout className={isCredits ? styles.layoutMobileCredits : styles.layoutMobile}>
                <Header className={lilacHeader ? styles.headerLilac : styles.header}>
                    <div className={styles.topHeader}>
                        <div className={styles.left}>
                            {leftHeader ? (
                                leftHeader
                            ) : (
                                <>
                                    <Button
                                        type="link"
                                        className={styles.drawerIcon}
                                        onClick={() => collapse(true)}
                                        icon={<Icon component={lilacHeader ? drawerIconWhite : drawerIcon} />}
                                    />
                                    <Link to="/">
                                        <Image src={lilacHeader ? raLogoWhite : raLogo} preview={false} />
                                    </Link>
                                </>
                            )}
                        </div>

                        {centerHeader && <div className={styles.centerHeader}>{centerHeader}</div>}

                        {rightHeader ? (
                            <div className={styles.rightHeader}>{rightHeader}</div>
                        ) : (
                            <div className={styles.rightHeader}>
                                <Button type="ghost" disabled></Button>
                            </div>
                        )}
                    </div>

                    {subHeader && <div className={styles.subHeader}>{subHeader}</div>}
                </Header>
                <Drawer
                    title={
                        <div className={styles.top}>
                            <Image src={rhodesAcademyLogo} preview={false} />
                            <Button type="link" className={styles.drawerClose} onClick={() => collapse(false)} icon={<Icon component={drawerCloseIcon} />} />
                        </div>
                    }
                    placement="left"
                    closable={false}
                    onClose={() => collapse(false)}
                    open={collapsed}
                    className={styles.drawer}
                    width="100%"
                >
                    {!!auth && (
                        <div className={styles.menuHeader}>
                            <div className={styles.profile}>
                                <Avatar className={styles.avatar} src={avatarExample} />
                                <div className={styles.profileInfo}>
                                    <span className={styles.name}>{auth?.name}</span>
                                    {auth.mode && (
                                        <span className={styles.rol}>
                                            {auth.mode === 'STUDENT' ? <FormattedMessage id="layout.student" /> : <FormattedMessage id="layout.instructor" />}
                                        </span>
                                    )}
                                </div>
                                <Button icon={<Icon component={logoutIcon} />} type="ghost" className={styles.logout} onClick={disconnect}></Button>
                            </div>
                            {auth?.authorities.includes('ROLE_STUDENT') && !auth.authorities.includes('ROLE_INSTRUCTOR') && (
                                <Link to="/become-instructor">
                                    <Button className={styles.becomeInstructor}>
                                        <FormattedMessage id="layout.becomeInstructor" />
                                    </Button>
                                </Link>
                            )}
                            {rolesService.hasAnyRole(auth, ['ROLE_INSTRUCTOR']) && auth.mode === 'STUDENT' && (
                                <Button onClick={switchToInstructor} className={styles.becomeInstructor}>
                                    <FormattedMessage id="layout.switchInstructor" />
                                </Button>
                            )}
                            {rolesService.hasAnyRole(auth, ['ROLE_INSTRUCTOR']) && auth.mode === 'INSTRUCTOR' && (
                                <Button onClick={switchToStudent} className={styles.becomeInstructor}>
                                    <FormattedMessage id="layout.switchStudent" />
                                </Button>
                            )}
                        </div>
                    )}
                    <MenuComponent menu={menu} collapsed={collapsed} desktop={desktop} />
                </Drawer>

                <Content className={lilacContent ? styles.lilacContent : styles.content}>
                    <div className={styles.main}>{children}</div>
                </Content>
            </Layout>
        );
    }
};
export default LayoutComponent;

interface Props extends PropsWithChildren {
    title: React.ReactNode | string;
    menu: MenuType;
    leftHeader?: React.ReactNode;
    centerHeader?: React.ReactNode;
    rightHeader?: React.ReactNode;
    subHeader?: React.ReactNode;
    lilacHeader?: boolean;
    lilacContent?: boolean;
    isCredits?: boolean;
}

const MenuComponent: React.FC<MenuProps> = (props) => {
    const { menu, desktop } = props;
    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;

    let menuItems: MenuItemType[] = [];

    if (!auth || !auth.initialized) {
        menuItems = [
            {
                key: 'home',
                label: (
                    <Link to="/">
                        <FormattedMessage id="layout.menu.home" />
                    </Link>
                ),
                icon: <Icon component={myCoursesIcon} className={styles.icon} />
            },
            {
                key: 'howItWorks',
                label: (
                    <Link to="/how-it-works">
                        <FormattedMessage id="layout.menu.howItWorks" />
                    </Link>
                ),
                icon: <Icon component={howIcon} className={styles.icon} />
            },
            {
                key: 'faq',
                label: (
                    <Link to="/faq">
                        <FormattedMessage id="layout.menu.faq" />
                    </Link>
                ),
                icon: <Icon component={faqIcon} className={styles.icon} />
            },
            {
                key: 'help',
                label: (
                    <Link to="/help">
                        <FormattedMessage id="layout.menu.help" />
                    </Link>
                ),
                icon: <Icon component={helpIcon} className={styles.icon} />
            }
        ];
    } else if (!!auth && auth.mode === 'STUDENT') {
        menuItems = [
            {
                key: 'student',
                label: (
                    <Link to="/student">
                        <FormattedMessage id="layout.menu.home" />
                    </Link>
                ),
                icon: <Icon component={myCoursesIcon} className={styles.icon} />
            },
            {
                key: 'courses',
                label: (
                    <Link to="/courses">
                        <FormattedMessage id="layout.menu.courses" />
                    </Link>
                ),
                icon: <Icon component={browseIcon} className={styles.icon} />
            },
            {
                key: 'myCourses',
                label: (
                    <Link to="/mycourses">
                        <FormattedMessage id="layout.menu.mycourses" />
                    </Link>
                ),
                icon: <Icon component={myCoursesIcon} className={styles.icon} />
            },
            {
                key: 'howItWorks',
                label: (
                    <Link to="/how-it-works">
                        <FormattedMessage id="layout.menu.howItWorks" />
                    </Link>
                ),
                icon: <Icon component={howIcon} className={styles.icon} />
            },
            {
                key: 'faq',
                label: (
                    <Link to="/faq">
                        <FormattedMessage id="layout.menu.faq" />
                    </Link>
                ),
                icon: <Icon component={faqIcon} className={styles.icon} />
            },
            {
                key: 'help',
                label: (
                    <Link to="/help">
                        <FormattedMessage id="layout.menu.help" />
                    </Link>
                ),
                icon: <Icon component={helpIcon} className={styles.icon} />
            },
            {
                key: 'settings',
                label: (
                    <Link to="/settings">
                        <FormattedMessage id="layout.menu.settings" />
                    </Link>
                ),
                icon: <Icon component={preferencesIcon} className={styles.icon} />
            }
        ];
    } else if (!!auth && auth.mode === 'INSTRUCTOR') {
        menuItems = [
            {
                key: 'instructor',
                label: (
                    <Link to="/instructor">
                        <FormattedMessage id="layout.menu.home" />
                    </Link>
                ),
                icon: <Icon component={myCoursesIcon} className={styles.icon} />
            },
            {
                key: 'howItWorks',
                label: (
                    <Link to="/how-it-works">
                        <FormattedMessage id="layout.menu.howItWorks" />
                    </Link>
                ),
                icon: <Icon component={howIcon} className={styles.icon} />
            },
            {
                key: 'faq',
                label: (
                    <Link to="/faq">
                        <FormattedMessage id="layout.menu.faq" />
                    </Link>
                ),
                icon: <Icon component={faqIcon} className={styles.icon} />
            },
            {
                key: 'help',
                label: (
                    <Link to="/help">
                        <FormattedMessage id="layout.menu.help" />
                    </Link>
                ),
                icon: <Icon component={helpIcon} className={styles.icon} />
            },
            {
                key: 'settings',
                label: (
                    <Link to="/settings">
                        <FormattedMessage id="layout.menu.settings" />
                    </Link>
                ),
                icon: <Icon component={preferencesIcon} className={styles.icon} />
            }
        ];
    } else if (!!auth && auth.authorities.includes('ROLE_ADMIN')) {
        menuItems = [
            {
                key: 'instructorRequests',
                label: (
                    <Link to="/instructor-requests">
                        <FormattedMessage id="layout.menu.instructorRequests" />
                    </Link>
                )
            },
            {
                key: 'adminInstructors',
                label: (
                    <Link to="/admin-instructors">
                        <FormattedMessage id="layout.menu.adminInstructors" />
                    </Link>
                )
            },
            {
                key: 'adminStudents',
                label: (
                    <Link to="/admin-students">
                        <FormattedMessage id="layout.menu.adminStudents" />
                    </Link>
                )
            },
            {
                key: 'adminCourses',
                label: (
                    <Link to="/admin-courses">
                        <FormattedMessage id="layout.menu.adminCourses" />
                    </Link>
                )
            }
        ];
    }

    return <Menu mode="inline" selectedKeys={[menu]} items={menuItems} className={desktop ? styles.menu : styles.mobileMenu} />;
};

interface MenuProps extends PropsWithChildren {
    menu: MenuType;
    collapsed: boolean;
    desktop: boolean;
}
