import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import alertService from '../../../services/AlertService';

/**
 * Returns admin instructors page.
 * @returns admin instructors page.
 */
const AdminInstructorsPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'loading'>();

    // initialize app
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl]);

    /*** METHODS ***/

    /*** VISUAL ***/

    return (
        <LayoutComponent title={<FormattedMessage id="adminInstructors.title" />} menu="adminInstructors">
            Instructors{loading && '...'}
            <br />
        </LayoutComponent>
    );
};
export default AdminInstructorsPage;
