import Icon from '@ant-design/icons';
import { Button, Col, Image, List, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import creditClient from '../../clients/CreditClient';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import { Credit } from '../../models/Entities';
import creditsPolygonBorderImage from '../../resources/images/credits-polygon-border.svg';
import creditsPolygonImage from '../../resources/images/credits-polygon.svg';
import { ReactComponent as dotsIcon } from '../../resources/images/dots-white-icon.svg';
import exampleTwoImage from '../../resources/images/example2.png';
import { ReactComponent as leftArrowIcon } from '../../resources/images/left-arrow-white-icon.svg';
import alertService from '../../services/AlertService';
import styles from './CreditsPage.module.scss';

/**
 * Returns credits page.
 * @returns credits page.
 */
const CreditsPage: React.FC = () => {
    /*** HOOKS ***/

    const navigate = useNavigate();
    const [mode, setMode] = useState<'credits' | 'badges'>('credits');

    /*** METHODS ***/

    /*** VISUAL ***/

    return (
        <LayoutComponent
            title={<FormattedMessage id="credits.title" />}
            menu="credits"
            isCredits
            leftHeader={<Button icon={<Icon component={leftArrowIcon} className={styles.icon} />} type="ghost" onClick={() => navigate(-1)}></Button>}
            centerHeader={
                <div className={styles.title}>
                    <FormattedMessage id="credits.title" />
                </div>
            }
            rightHeader={<Button icon={<Icon component={dotsIcon} className={styles.icon} />} type="ghost"></Button>}
        >
            <div className={styles.panel}>
                <div className={styles.polygons}>
                    <div className={styles.imageContainer} onClick={() => setMode('credits')}>
                        <div className={styles.credits}>
                            {10}
                            <div className={styles.mc}>
                                <FormattedMessage id="credits.mcs" />
                            </div>
                        </div>
                        {mode === 'credits' ? (
                            <Image src={creditsPolygonBorderImage} preview={false} className={styles.polygon} />
                        ) : (
                            <Image src={creditsPolygonImage} preview={false} className={styles.polygon} />
                        )}
                    </div>
                    <div className={styles.imageContainer} onClick={() => setMode('badges')}>
                        <div className={styles.credits}>
                            {7}
                            <div className={styles.mc}>
                                <FormattedMessage id="credits.badges" />
                            </div>
                        </div>
                        {mode === 'badges' ? (
                            <Image src={creditsPolygonBorderImage} preview={false} className={styles.polygon} />
                        ) : (
                            <Image src={creditsPolygonImage} preview={false} className={styles.polygon} />
                        )}
                    </div>
                </div>
                {mode === 'credits' ? <CreditsComponent /> : <BadgesComponent />}
            </div>
        </LayoutComponent>
    );
};
export default CreditsPage;

/**
 * Returns the credits component.
 * @returns the credits component
 */
const CreditsComponent = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'loading'>();
    const [credits, setCredits] = useState<Credit[]>([]);

    /*** EFFECTS ***/

    // initialize app
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const credits = await creditClient.getStudentCredits();
                setCredits(credits);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl]);

    /*** METHODS ***/

    /*** COMPONENTS */

    return (
        <>
            <div className={styles.mcs}>
                <FormattedMessage id="credits.mcsEarned" />
            </div>
            <List
                grid={{
                    column: 1
                }}
                dataSource={credits}
                loading={loading === 'loading'}
                renderItem={(credit) => {
                    return (
                        <List.Item key={credit.id} className={styles.card}>
                            <Row className={styles.credit}>
                                <Col span={4}>
                                    <Image src={exampleTwoImage} preview={false} className={styles.image} />
                                </Col>
                                <Col span={17} className={styles.info}>
                                    <div className={styles.course}>{credit.course}</div>
                                </Col>
                                <Col span={3}>
                                    <div className={styles.imageContainer}>
                                        <div className={styles.credits}>
                                            {credit.total}{' '}
                                            <div className={styles.mc}>
                                                <FormattedMessage id="credits.mc" />
                                            </div>
                                        </div>
                                        <Image src={creditsPolygonImage} preview={false} className={styles.creditsContainer} />
                                    </div>
                                </Col>
                            </Row>
                        </List.Item>
                    );
                }}
            />
        </>
    );
};

/**
 * Returns the badges component.
 * @returns the badges component
 */
const BadgesComponent = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'loading'>();
    const [credits, setCredits] = useState<Credit[]>([]);

    /*** EFFECTS ***/

    // initialize app
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const credits = await creditClient.getStudentCredits();
                setCredits(credits);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl]);

    /*** METHODS ***/

    /*** COMPONENTS */

    return (
        <>
            <div className={styles.mcs}>
                <FormattedMessage id="credits.badges" />
            </div>
            <List
                grid={{
                    column: 1
                }}
                dataSource={credits}
                loading={loading === 'loading'}
                renderItem={(credit) => {
                    return (
                        <List.Item key={credit.id}>
                            <div className={styles.badges}>
                                <div>
                                    <Image src={creditsPolygonImage} preview={false} className={styles.polygon} />
                                    <div className={styles.course}>{credit.course}</div>
                                </div>
                                <div>
                                    <Image src={creditsPolygonImage} preview={false} className={styles.polygon} />
                                    <div className={styles.course}>{credit.course}</div>
                                </div>
                            </div>
                        </List.Item>
                    );
                }}
            />
        </>
    );
};
