import { ConfigProvider, message } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import moment from 'moment';
import { useEffect } from 'react';
import appThemeComponent from './components/ThemeComponent/ThemeComponent';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { polygonMumbai } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import UserApp from './pages/UserApp/UserApp';

//setup web3 client
const { provider } = configureChains([polygonMumbai], [publicProvider()]);
const client = createClient({
    provider,
    autoConnect: true
});

/**
 * Returns the app.
 * @returns the app.
 */
const App: React.FC = () => {
    /*** HOOKS ***/

    // initialize app
    useEffect(() => {
        const init = async () => {
            // set-up antd configuration
            message.config({
                maxCount: 1
            });

            // set-up locale
            moment.locale('en');
        };
        init();
    }, []);

    /*** VISUAL ***/

    return (
        <ConfigProvider locale={enUS} theme={appThemeComponent.getTheme()}>
            <WagmiConfig client={client}>
                <UserApp />
            </WagmiConfig>
        </ConfigProvider>
    );
};
export default App;
