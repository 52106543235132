import { Course, Instructor } from '../models/Entities';

/**
 * This class represents the courses client.
 */
class CourseClient {
    /**
     * Retrieves the courses of an instructor.
     * @param userId the user id
     * @returns the wallet
     */
    listInstructorCourses = async (userId: string): Promise<Course[]> => {
        // TODO: retrieve courses from remote system

        return instructorCourses;
    };

    /**
     * Get a instructor course by id.
     * @param courseId the course id
     * @returns the course
     */
    getInstructorCourse = async (courseId: string): Promise<Course | undefined> => {
        const course = instructorCourses.find((course) => course.id === courseId);

        return course;
    };

    /**
     * Retrieves the courses of a student.
     * @param userId the user id
     * @returns the wallet
     */
    listStudentCourses = async (userId: string): Promise<Course[]> => {
        // TODO: retrieve courses from remote system

        return courses;
    };

    /**
     * Get a course by id.
     * @param courseId the course id
     * @returns the course
     */
    get = async (courseId: string): Promise<Course | undefined> => {
        const course = courses.find((course) => course.id === courseId);

        return course;
    };
}

const courseClient: CourseClient = new CourseClient();
export default courseClient;

const author: Instructor = { id: '1', name: 'Peter Adkins', students: 10, courses: [], instructor: true, student: false };
// mock data
const courses: Course[] = [...Array(3)].map((v, i) => ({
    id: `${i + 1}`,
    name: `Course ${i + 1}`,
    description: `Course ${i + 1} description`,
    credits: 10,
    status: 'Open',
    seats: 10,
    author: author,
    currentLessons: 2,
    totalLessons: 10,
    lessons: [
        {
            id: '1',
            title: 'Lesson 1',
            videos: [
                { name: 'Introduction', duration: '3:49' },
                { name: 'Conclusions', duration: '3:49' }
            ]
        },
        {
            id: '2',
            title: 'Lesson 2',
            videos: [
                { name: 'Introduction', duration: '3:49' },
                { name: 'Conclusions', duration: '3:49' }
            ]
        }
    ],
    price: 50
}));

const instructorCourses: Course[] = [...Array(3)].map((v, i) => ({
    id: `${i + 1}`,
    name: `Course ${i + 1}`,
    description: `Course ${i + 1} description`,
    author: author,
    status: 'Open',
    seats: 10,
    students: [
        { id: '1', name: 'Peter Parker' },
        { id: '2', name: 'Ben Williams' }
    ],
    assignments: [
        { id: '1', title: 'Assignment 1', description: 'Assignment 1 description', file: 'file.zip' },
        { id: '2', title: 'Assignment 2', description: 'Assignment 2 description', file: 'file.zip' }
    ],
    lessons: [
        {
            id: '1',
            title: 'Lesson 1',
            videos: [
                { name: 'Introduction', duration: '3:49' },
                { name: 'Conclusions', duration: '3:49' }
            ]
        },
        {
            id: '2',
            title: 'Lesson 2',
            videos: [
                { name: 'Introduction', duration: '3:49' },
                { name: 'Conclusions', duration: '3:49' }
            ]
        }
    ]
}));
