import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Avatar, Button } from 'antd';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import alertService from '../../services/AlertService';
import styles from './IntroPage.module.scss';

/**
 * Returns the intro page.
 * @returns the intro page.
 */
const IntroPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();

    // initialize app
    useEffect(() => {
        const init = async () => {
            try {
                // TODO: load page data (probably calling a client method )
            } catch (error) {
                alertService.displayError(error, intl);
            }
        };
        init();
    }, [intl]);

    /*** METHODS ***/

    /*** VISUAL ***/

    return (
        <LayoutComponent
            title={<FormattedMessage id="intro.title" />}
            menu="home"
            leftHeader={
                <Link to="/">
                    <Button icon={<ArrowLeftOutlined />} type="ghost"></Button>
                </Link>
            }
            centerHeader={
                <div className="pageTitle">
                    <FormattedMessage id="intro.title" />
                </div>
            }
        >
            <div className={styles.panel}>
                <Avatar className={styles.avatar} />

                <h1 className={styles.title}>
                    <FormattedMessage id="intro.title" />
                </h1>

                <div className={styles.description}></div>
                <FormattedMessage id="home.text2" />

                <div className={styles.buttons}>
                    <Link to="/metamask">
                        <Button icon={<ArrowRightOutlined />} type="primary" shape="circle" className={styles.next}></Button>
                    </Link>
                </div>
            </div>
        </LayoutComponent>
    );
};
export default IntroPage;
