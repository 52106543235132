import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import CustomContext from '../../contexts/CustomContext';
import { AuthorityType } from '../../models/Types';

/**
 * This component represents a protected route. It redirect to the home page if the user is not authorized to view a route.
 * @param props - the route props
 */
const ProtectedRoute: React.FC<Props> = (props) => {
    const { authorities, initialized, redirectPath, children } = props;
    const context = useContext(CustomContext);
    const { auth } = context;

    // TODO: authentication not working
    if (auth && auth.initialized === initialized && (authorities.length === 0 || auth.authorities.some((a) => authorities.includes(a)))) {
        return children ? children : <Outlet />;
    } else {
        return <Navigate to={redirectPath || '/'} />;
    }
};
export default ProtectedRoute;

interface Props {
    children?: React.ReactElement;
    authorities: AuthorityType[];
    initialized: boolean;
    redirectPath?: string;
}
