import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Image, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../contexts/CustomContext';
import { useWalletConnect } from '../../hooks/useRhodes';
import walletImage from '../../resources/images/wallet.svg';
import alertService from '../../services/AlertService';
import authService from '../../services/AuthService';
import rolesService from '../../services/RolesService';
import styles from './WalletPage.module.scss';

/**
 * Returns the wallet page.
 * @returns the wallet page.
 */
const WalletPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const navigate = useNavigate();
    const context = useContext(CustomContext);
    const { auth } = context;
    const [loading, setLoading] = useState<'loading'>();
    const { isLoading, pendingConnector, connectError, isConnected, connect } = useWalletConnect();

    // initialize app
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');

                if (auth && auth.initialized && rolesService.hasAnyRole(auth, ['ROLE_ADMIN'])) {
                    navigate('/instructor-requests');
                } else if (auth && auth.initialized && auth.mode === 'STUDENT') {
                    navigate('/student');
                } else if (auth && auth.initialized && auth.mode === 'INSTRUCTOR') {
                    navigate('/instructor');
                } else if (auth && !auth.initialized) {
                    navigate('/signup');
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [auth, intl, navigate]);

    /*** METHODS ***/
    /*
    const connectToWallet = async () => {
        try {
            setLoading('loading');

            if (isConnected) {
                await disconnectAsync();
            }

            // enabling the web3 provider metamask
            const { account } = await connectAsync({
                connector
            });

            const auth = await authService.get(account);
            context.setAuth(auth);
            navigate('/signup');
        } catch (error: any) {
            // error managed in wagmi errors
        } finally {
            setLoading(undefined);
        }
    };
*/
    /*** VISUAL ***/

    return (
        <LayoutComponent
            title={<FormattedMessage id="wallet.title" />}
            menu="home"
            leftHeader={
                <Link to="/metamask">
                    <Button icon={<ArrowLeftOutlined />} type="ghost"></Button>
                </Link>
            }
            centerHeader={
                <div className="pageTitle">
                    <FormattedMessage id="wallet.step" />
                </div>
            }
        >
            {/* <div>
                {connectors.map((connector) => (
                    <button disabled={!connector.ready} key={connector.id} onClick={() => connectAsync({ connector })}>
                        {connector.name}
                        {!connector.ready && ' (unsupported)'}
                        {isLoading && connector.id === pendingConnector?.id && ' (connecting)'}
                    </button>
                ))}

                {error && <div>{error.message}</div>}
            </div> */}
            <div className={styles.panel}>
                <Row gutter={[28, 0]}>
                    <Col xs={24} className={styles.textAlign}>
                        <Image src={walletImage} preview={false} />
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col xs={24} className={styles.title}>
                        <h1>
                            <FormattedMessage id="wallet.text1" />
                        </h1>
                    </Col>
                </Row>
                <Row gutter={[28, 0]} className={styles.description}>
                    <Col xs={24}>
                        <FormattedMessage id="wallet.text2" />
                    </Col>
                </Row>
                <Row gutter={[28, 0]} className={styles.buttons}>
                    <Col xs={24}>
                        <Button onClick={connect} type="primary" className="buttons">
                            <FormattedMessage id="wallet.connect" />
                        </Button>
                    </Col>
                </Row>
                {connectError && (
                    <Row className={styles.error}>
                        <Col xs={24}>{connectError.message}</Col>
                    </Row>
                )}
            </div>
        </LayoutComponent>
    );
};
export default WalletPage;
