import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import alertService from '../../services/AlertService';

/**
 * Returns how it works page.
 * @returns how it works page.
 */
const HowItWorksPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'loading'>();

    // initialize app
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl]);

    /*** METHODS ***/

    /*** VISUAL ***/

    return (
        <LayoutComponent title={<FormattedMessage id="howItWorks.title" />} menu="howItWorks">
            How it Works{loading && '...'}
            <br />
        </LayoutComponent>
    );
};
export default HowItWorksPage;
