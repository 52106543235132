import { Button, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import alertService from '../../services/AlertService';
import styles from './SettingsPage.module.scss';

/**
 * Returns settings page.
 * @returns settings page.
 */
const SettingsPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'loading'>();

    // initialize app
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl]);

    /*** METHODS ***/

    /*** VISUAL ***/

    return (
        <LayoutComponent title={<FormattedMessage id="settings.title" />} menu="settings">
            <h1 className={styles.title}>
                <FormattedMessage id="settings.title" />
            </h1>
            <Form /* form={form} onFinish={save} */ colon={false} layout="vertical">
                <Form.Item name="name" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}>
                    <Input placeholder={intl.formatMessage({ id: 'settings.name' })} className="inputs" />
                </Form.Item>
                <Form.Item
                    name="email"
                    rules={[
                        { required: true, message: <FormattedMessage id="status.mandatory" /> },
                        {
                            type: 'email',
                            message: <FormattedMessage id="status.email.invalid" />
                        }
                    ]}
                >
                    <Input placeholder={intl.formatMessage({ id: 'settings.email' })} className="inputs" />
                </Form.Item>
                <Form.Item name="phoneNumber">
                    <Input placeholder={intl.formatMessage({ id: 'settings.phoneNumber' })} className="inputs" />
                </Form.Item>
                <Form.Item name="address">
                    <Input placeholder={intl.formatMessage({ id: 'settings.address' })} className="inputs" />
                </Form.Item>
                <Form.Item name="city">
                    <Input placeholder={intl.formatMessage({ id: 'settings.city' })} className="inputs" />
                </Form.Item>

                <div className={styles.buttons}>
                    <Button type="primary" htmlType="submit" loading={loading === 'loading'} className="buttons">
                        <FormattedMessage id="button.save" tagName="span" />
                    </Button>
                </div>
            </Form>
        </LayoutComponent>
    );
};
export default SettingsPage;
