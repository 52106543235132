import Icon from '@ant-design/icons';
import { Button, Form, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import creditClient from '../../clients/CreditClient';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../contexts/CustomContext';
import { Credit, CustomAuth } from '../../models/Entities';
import { ReactComponent as dotsIcon } from '../../resources/images/dots-white-icon.svg';
import { ReactComponent as leftArrowIcon } from '../../resources/images/left-arrow-white-icon.svg';
import { ReactComponent as downloadIcon } from '../../resources/images/download-green-icon.svg';
import { ReactComponent as folderIcon } from '../../resources/images/folder-icon.svg';
import alertService from '../../services/AlertService';
import styles from './AssignmentPage.module.scss';

/**
 * Returns assignment page.
 * @returns assignment page.
 */
const AssignmentPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const params = useParams<ParamsType>();
    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<'loading'>();
    const [credits, setCredits] = useState<Credit[]>([]);

    // initialize app
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const credits = await creditClient.getStudentCredits();
                setCredits(credits);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [auth.id, intl]);

    /*** METHODS ***/

    /*** VISUAL ***/

    return (
        <LayoutComponent
            title={<FormattedMessage id="assignment.title" />}
            menu="assignment"
            lilacHeader
            leftHeader={<Button icon={<Icon component={leftArrowIcon} className={styles.icon} />} type="ghost" onClick={() => navigate(-1)}></Button>}
            centerHeader={<div className={styles.header}>Marielle Wigington</div>}
            rightHeader={<Button icon={<Icon component={dotsIcon} className={styles.icon} />} type="ghost"></Button>}
            subHeader={<div className={styles.title}>Supervisor Leadership Skills for a Safe Workplace</div>}
        >
            <div className={styles.submission}>
                <FormattedMessage id="assignment.submission" />
            </div>
            <div className={styles.content}>
                <div className={styles.file}>
                    <Icon component={folderIcon} className={styles.icon} />
                    <span className={styles.fileName}>
                        <div className={styles.name}>MWigington-Essay.doc</div>
                        <div className={styles.submitted}>Submitted Yesterday</div>
                    </span>
                </div>
                <Button icon={<Icon component={downloadIcon} className={styles.download} />} type="ghost"></Button>
            </div>
            <div className={styles.submission}>
                <FormattedMessage id="assignment.connected" />
            </div>
            <div className={styles.lesson}>
                <div className={styles.workplace}>A New Workplace Culture Model</div>
                <div></div>
                <div className={styles.video}>
                    <div className={styles.senior}>Working with Senior Management</div>
                    <div className={styles.time}>4:00</div>
                </div>
            </div>
            <div className={styles.submission}>
                <FormattedMessage id="assignment.grading" />
            </div>
            <Form form={form} /* onFinish={save} */ colon={false} layout="vertical">
                <Form.Item name="grade">
                    <Select placeholder={intl.formatMessage({ id: 'assignment.grade' })} className={styles.select}>
                        {}
                    </Select>
                </Form.Item>
                <Form.Item name="comments">
                    <TextArea placeholder={intl.formatMessage({ id: 'assignment.comments' })} className={styles.textArea} />
                </Form.Item>
                <Button type="primary" htmlType="submit" loading={loading === 'loading'} className="buttons">
                    <FormattedMessage id="assignment.submitGrade" />
                </Button>
            </Form>
        </LayoutComponent>
    );
};
export default AssignmentPage;

type ParamsType = { id: string };
