import Icon, { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Image, Row, Tabs, Collapse } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import courseClient from '../../clients/CourseClient';
import { useRhodesCourse } from '../../hooks/useRhodes';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import { Course } from '../../models/Entities';
import avatarImage from '../../resources/images/avatar-example1.svg';
import exampleImage from '../../resources/images/example1.png';
import { ReactComponent as usersIcon } from '../../resources/images/users-icon.svg';
import { ReactComponent as playIcon } from '../../resources/images/play-icon.svg';
import { ReactComponent as heartIcon } from '../../resources/images/heart-icon.svg';
import { ReactComponent as downArrowIcon } from '../../resources/images/down-arrow-icon.svg';
import { ReactComponent as playFilledIcon } from '../../resources/images/play-filled-icon.svg';
import alertService from '../../services/AlertService';
import './CoursePage.css';
import styles from './CoursePage.module.scss';

const { Panel } = Collapse;

/**
 * Returns course page.
 * @returns course page.
 */
const CoursePage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const params = useParams<ParamsType>();
    const navigate = useNavigate();
    //const [loading, setLoading] = useState<'loading'>();
    //const [course, setCourse] = useState<Course>();
    const {
        course,
        courseLoading: loading,
        courseError: error
    } = useRhodesCourse(params.id!);
    
/*
    // initialize app
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const course = await courseClient.get(params.id!);
                setCourse(course);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, params.id]);
*/
    /*** METHODS ***/

    /*** VISUAL ***/

    const items = [
        {
            label: <FormattedMessage id="course.about" />,
            key: 'about',
            children: course?.name && <AboutComponent course={course} />
        },
        {
            label: <FormattedMessage id="course.lessons" />,
            key: 'lessons',
            children: course?.name && <LessonsComponent course={course} />
        },
        {
            label: <FormattedMessage id="course.reviews" />,
            key: 'reviews',
            children: <></>
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="course.title" />}
            menu="course"
            leftHeader={<Button icon={<ArrowLeftOutlined />} type="ghost" onClick={() => navigate(-1)}></Button>}
            centerHeader={
                <div className="pageTitle">
                    <FormattedMessage id="course.title" />
                </div>
            }
        >
            <div className={styles.panel}>
                <div className={styles.courseName}>{course?.name}</div>
                <div className={styles.imageContainer}>
                    <Image src={exampleImage} preview={false} className={styles.image} />
                </div>
                <Tabs items={items} className={styles.tabs} />
            </div>
        </LayoutComponent>
    );
};
export default CoursePage;

type ParamsType = { id: string };

/**
 * Returns the about component.
 * @returns the about component
 */
const AboutComponent = (props: any): React.ReactElement => {
    const { course } = props;
    /*** HOOKS ***/

    const {
        //course,
        seat,
        mintSeat,
        registerSeat
    } = useRhodesCourse(course.id!);

    /*** EFFECTS ***/

    /*** METHODS ***/

    /*** COMPONENTS */

    return (
        <>
            <div className={styles.sectionTitle}>
                <FormattedMessage id="course.description" />
            </div>
            <div className={styles.sectionDesc}>{course.description}</div>
            <div className={styles.sectionTitle}>
                <FormattedMessage id="course.instructor" />
            </div>
            <div className={styles.author}>
                <Row>
                    <Col span={4}>
                        <Image src={avatarImage} preview={false} className={styles.image} />
                    </Col>
                    <Col span={20} className={styles.info}>
                        <div className={styles.name}>{course?.author?.name}</div>
                        <div className={styles.details}>
                            <span>
                                <Icon component={usersIcon} className={styles.icon} />{' '}
                                <FormattedMessage id="course.students" values={{ value: course?.author?.students }} />
                            </span>
                            <span>
                                <Icon component={playIcon} className={styles.icon} />{' '}
                                <FormattedMessage id="course.courses" values={{ value: course?.author?.courses?.length }} />
                            </span>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className={styles.sectionTitle}>
                <FormattedMessage id="course.lessons" />
            </div>
            {course.lessons &&
                course.lessons.map((lesson: any) => {
                    return (
                        <Collapse className={styles.lessons} defaultActiveKey={course.lessons?.find(Boolean)?.id}>
                            <Panel
                                header={
                                    <div className={styles.header}>
                                        <span className={styles.lesson}>{lesson.title}</span>
                                        <Icon component={downArrowIcon} className={styles.arrowDown} />
                                    </div>
                                }
                                key={lesson.id}
                            >
                                {lesson.videos?.map((video: any) => {
                                    return (
                                        <div className={styles.content}>
                                            <div className={styles.playName}>
                                                <Icon component={playFilledIcon} className={styles.play} />
                                                <span className={styles.name}>{video.name}</span>
                                            </div>
                                            <div className={styles.duration}>{video.duration}</div>
                                        </div>
                                    );
                                })}
                            </Panel>
                        </Collapse>
                    );
                })}
            {
                (!seat.seat) ? (
                    <Button type="primary" className="buttons" onClick={() => mintSeat(course.id)}>
                        <FormattedMessage id="course.enroll" values={{ price: course.cost }} />
                    </Button>
                ) : (!seat.registered) ? (
                    <Button type="primary" className="buttons" onClick={() => registerSeat(course.id, seat.seat)}>
                        {`Register Seat ${seat.seat}`}
                    </Button>
                ) : (
                    <span>{`Seat ${seat.seat} Registered`}</span>
                )
            }
            
        </>
    );
};

interface AboutProps {
    course: Course;
}

/**
 * Returns the lessons component.
 * @returns the lessons component
 */
const LessonsComponent = (props: any): React.ReactElement => {
    const { course } = props;
    /*** HOOKS ***/

    /*** EFFECTS ***/

    /*** METHODS ***/

    /*** COMPONENTS */

    return (
        <>
            {course.lessons &&
                course.lessons.map((lesson: any) => {
                    return (
                        <Collapse className={styles.lessons} defaultActiveKey={course.lessons?.find(Boolean)?.id}>
                            <Panel
                                header={
                                    <div className={styles.header}>
                                        <span className={styles.lesson}>{lesson.title}</span>
                                        <Icon component={downArrowIcon} className={styles.arrowDown} />
                                    </div>
                                }
                                key={lesson.id}
                            >
                                {lesson.videos?.map((video: any) => {
                                    return (
                                        <div className={styles.content}>
                                            <div className={styles.playName}>
                                                <Icon component={playFilledIcon} className={styles.play} />
                                                <span className={styles.name}>{video.name}</span>
                                            </div>
                                            <div className={styles.duration}>{video.duration}</div>
                                        </div>
                                    );
                                })}
                            </Panel>
                        </Collapse>
                    );
                })}
            <div className={styles.buttons}>
                <Button icon={<Icon component={heartIcon} className={styles.heart} />} className={styles.fav}></Button>
                <Button type="primary" className="buttons">
                    <FormattedMessage id="course.buy" values={{ price: course.cost }} />
                </Button>
            </div>
        </>
    );
};

interface LessonsProps {
    course: Course;
}
