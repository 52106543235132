import userClient from '../clients/UserClient';
import { CustomAuth, User } from '../models/Entities';
import { AuthorityType } from '../models/Types';

class AuthService {
    auth: CustomAuth | undefined;

    /**
     * Returns the authenticated user (using her wallet).
     * @returns the authenticated user
     */
    get = async (account: string): Promise<CustomAuth | undefined> => {
        try {
            if (account) {
                this.auth = await this.createAuth(account);
            }
        } catch {
            this.auth = undefined;
        }

        return this.auth;
    };

    /**
     * Signs up a user (using her wallet).
     * @returns the authenticated user
     */
    signUp = async (walletAccountId: string): Promise<CustomAuth | any> => {
        try {
            this.auth = await this.createAuth(walletAccountId);
        } catch {
            this.auth = undefined;
        }

        return this.auth;
    };

    /**
     * Signs out a user.
     * @returns the user
     */
    signOut = () => {
        try {
            // TODO: sign out from wallet
            this.auth = undefined;
        } catch {
            this.auth = undefined;
        }

        return this.auth;
    };

    /**
     * Returns the auth from the user.
     * @returns the auth
     */
    private async createAuth(walletAccountId: string): Promise<CustomAuth | undefined> {
        // get current user
        let customAuth: CustomAuth | undefined;
        try {
            const currentUser = await userClient.getCurrent();
            const authorities = this.createAuthorities(currentUser);
            customAuth = {
                id: walletAccountId,
                email: currentUser.email,
                name: currentUser.name,
                authorities,
                initialized: true,
                mode: authorities.includes('ROLE_INSTRUCTOR') ? 'INSTRUCTOR' : 'STUDENT'
            };
        } catch (error: any) {
            // user not authenticated - non initialized user
            // if (error && error.response && error.response.status === 404) {
            customAuth = {
                id: walletAccountId,
                email: '',
                name: '',
                authorities: [],
                initialized: false
            };
            // }
        }

        return customAuth;
    }

    /**
     * Returns the authorities of a user.
     * @param user the user
     * @returns the authorities
     */
    private createAuthorities(user: User): AuthorityType[] {
        const authorities: AuthorityType[] = [];
        if (user.instructor) {
            authorities.push('ROLE_INSTRUCTOR');
            authorities.push('ROLE_STUDENT');
        } else if (user.student) {
            authorities.push('ROLE_STUDENT');
        }

        return authorities;
    }
}

const authService: AuthService = new AuthService();
export default authService;
