import { useState, useCallback, useEffect, useContext } from "react";
import { providers, utils, Contract } from "ethers";
import { useAccount, useConnect, useDisconnect, useContract, useSigner } from 'wagmi';
import { getProvider, getNetwork } from '@wagmi/core';
import RhodesCourse from "./contractABIs/RhodesCourse.json";
import RhodesRegistry from "./contractABIs/RhodesRegistry.json";
import AssignmentRegistry from "./contractABIs/AssignmentRegistry.json";
import CustomContext from '../contexts/CustomContext';
import { CustomAuth, User, Course } from '../models/Entities';
import { AuthorityType, MenuType } from '../models/Types';
import userClient from '../clients/UserClient';
import { useNavigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import {
  getFirestore,
  getDocs,
  collection,
  getDoc,
  doc,
  setDoc,
  addDoc,
  updateDoc,
  query,
  where
} from "firebase/firestore";
import { useCollection, useDocument } from 'react-firebase-hooks/firestore';

const REGISTRY_MUMBAI_ADDY = "0x0Cd03963356765e6beC69c380f18d2100d274106" ;

const app = initializeApp({
  apiKey: "AIzaSyA1U8sFQfjOqOnH2TiqDF7SGf0N-hYOG10",
  authDomain: "rhodes-9c0ac.firebaseapp.com",
  projectId: "rhodes-9c0ac",
  storageBucket: "rhodes-9c0ac.appspot.com",
  messagingSenderId: "272999872812",
  appId: "1:272999872812:web:19714a5d066a9d1820d489",
  measurementId: "G-1BF5QSM0NT"
});
const db = getFirestore(app);
const userQuery = (id) => doc(db, "users", id);
const instructorRequestsQuery = query(collection(db, "instructors"), where("approved", "==", false))
const coursesQuery = query(collection(db, "courses"));
const courseQuery = (id) => doc(db, "courses", id);
const courseRequestsQuery = query(collection(db, "courses"), where("approved", "==", false))
//const courseQuery = (id) => query(collection(db, "courses"), where("id", "==", id))
//doc(getFirestore(firebaseApp), 'hooks', 'nBShXiRGFAhuiPfBaGpt')
//const userCoursesQuery = (id) => query(collection(db, "courses"), where("instructor", "==", id))
const instructorCoursesQuery = (id) => query(collection(db, "courses"), where("instructor", "==", id))
const studentCoursesQuery = (account) => query(collection(db, "course-seats"), where("owner", "==", account))
const courseSeatsQuery = (course) => query(collection(db, "course-seats"), where("course", "==", course))
const accountSeatQuery = (course, account) => query(collection(db, "course-seats"), where("course", "==", course), where("owner", "==", account))
const courseSeatQuery = (course, seat) => query(collection(db, "course-seats"), where("course", "==", course), where("seat", "==", seat))

//const userQuery = query(collection(db, "users"), where("id", "==", "1"));
//const usersQuery = query(collection(db, "users"));
//const userSnapshot = await getDocs(usersQuery);
// doc.data() is never undefined for query doc snapshots
//const userList = userSnapshot.docs.map(doc => doc.data());
//console.log('userList', userList);

const initUser = async (provider, account) => {
  const contract = new Contract(REGISTRY_MUMBAI_ADDY, RhodesRegistry.abi, provider);
  const userId: string = (await contract.userId(account)).toString();
  const isRegistered: boolean = Number(userId) > 0;
  let user: User | undefined;
  if (isRegistered) {
    user = (await getDoc(userQuery(userId))).data();
    user.student = isRegistered;
    user.instructor = await contract.instructorApproved(userId);
    user.admin = await contract.admin(account);
  }
  userClient.create(user);
  return user;
}

const initAuth = (user: User | undefined) => {
  let customAuth: CustomAuth | undefined;
  if (user?.student) {
    const { id, name, email } = user;

    const authorities: AuthorityType[] = ['ROLE_STUDENT'];
    if (user.instructor) {
        authorities.push('ROLE_INSTRUCTOR');
    }
    if (user.admin) {
        authorities.push('ROLE_ADMIN');
    }
    const mode = user.instructor ? 'INSTRUCTOR' : 'STUDENT';
    customAuth = {
        id,
        email,
        name,
        authorities,
        initialized: true,
        mode
    };
  } else {
    customAuth = {
        id: '',
        email: '',
        name: '',
        authorities: [],
        initialized: false
    };
  }

  return customAuth;
}

const removeNullUndefined = obj => Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));

export const useRhodesAuth = () => {
  const { address: account, isConnected } = useAccount();
  const { auth, setAuth }  = useContext(CustomContext);
  const navigate = useNavigate();
  const provider = getProvider();

  useEffect(() => {
    (async () => {
      if (provider && isConnected) {
        try {
          console.log("account: ", account)
          const user = await initUser(provider, account);
          console.log('user', user);
          const customAuth = initAuth(user);
          console.log("auth: ", customAuth)
          setAuth(customAuth);

          // TODO: authentication not working
          if (customAuth.authorities.includes('ROLE_ADMIN')) {
            navigate('/instructor-requests');
          } else if (customAuth.authorities.includes('ROLE_INSTRUCTOR')) {
            navigate('/instructor');
          } else if (customAuth.authorities.includes('ROLE_STUDENT')) {
            navigate('/student');
          } else {
            navigate('/signup');
          }
        } catch (e) {
          console.error(e)
          //navigate('/error');
        }
      } else {
        setAuth(undefined);
        navigate('/');
      }
    })()
  }, [provider, account, isConnected])

  return {
    auth
  }
}

export const useWalletConnect = () => {

  const { connect: connectWallet, connectors, error, isLoading, pendingConnector } = useConnect();
  const { disconnect: disconnectWallet } = useDisconnect();
  const { address: account, connector, isConnected } = useAccount();
  const { auth, setAuth }  = useContext(CustomContext);

  const connect = useCallback(async () => {
    if (!isConnected) {
      try {
        //console.log("wallet connecting")
        if (connectors.length)  {
          connectWallet({ connector: connectors[0] });
        } else {
          connectWallet({ connector });
        }
      } catch (e) {
        console.error(e)
      }
    }
  }, [connectors, connector, connectWallet])

  const disconnect = useCallback(() => {
    try {
      //console.log("wallet disconnecting")
      if (isConnected) disconnectWallet();
      setAuth(undefined);
    } catch (e) {
      console.error(e)
    }
  }, [disconnectWallet, setAuth])

  return {
    isLoading,
    pendingConnector,
    connectError: error,
    isConnected,
    account,
    connect,
    disconnect
  }
}

export const useRegisterUserTx = () => {

  const { address: account, isConnected } = useAccount();
  const { data: signer, isError } = useSigner();
  const { auth, setAuth }  = useContext(CustomContext);
  const navigate = useNavigate();

  const registerUser = useCallback(
    async (_user: User) => {
      if (isConnected && signer && _user) {
        try {
          const contract = new Contract(REGISTRY_MUMBAI_ADDY, RhodesRegistry.abi, signer)
          let _tx = await contract.connect(signer).registerUser()
          console.log("tx hash: ", _tx?.hash)
          let _receipt = await _tx.wait()
          console.log("tx receipt: ", _receipt)

          const userId: string = (await contract.userId(account)).toString();
          const isRegistered: boolean = Number(userId) > 0;

          if (isRegistered) {
            await setDoc(doc(db, "users", userId), {
              ...removeNullUndefined(_user),
              id: userId
            });

            const user = await initUser(signer, account);
            console.log('new user', user);
            const customAuth = initAuth(user);
            console.log("auth: ", customAuth);
            setAuth(customAuth);
            navigate('/student');

          } else {
            setAuth(undefined);
            navigate('/');
          }

        } catch (error) {
          console.error("tx Error: ", error)
        }
      }
    },
    [isConnected, signer]
  )

  return {
    registerUser,
    isError,
    account
  }
}

export const useRequestInstructorTx = () => {

  const { address: account, isConnected } = useAccount();
  const { data: signer, isError } = useSigner();
  const { auth }  = useContext(CustomContext);
  //add txInProgress boolean state

  const requestInstuctor = useCallback(
    async (_instructor) => {
      console.log("_instructor: ", _instructor)
      const userId: string = auth.id;
      const isRegistered: boolean = Number(userId) > 0;
      console.log("userId: ", userId, isRegistered)

      if (isConnected && signer && _instructor && isRegistered) {
        try {
          const contract = new Contract(REGISTRY_MUMBAI_ADDY, RhodesRegistry.abi, signer)

          let _tx = await contract.connect(signer).requestInstructor()
          console.log("tx hash: ", _tx?.hash)
          let _receipt = await _tx.wait()
          console.log("tx receipt: ", _receipt)

          //check if tx !failed, _receipt.status == 1, success | 0, fail

          await setDoc(doc(db, "instructors", userId), {
            ...removeNullUndefined(_instructor),
            id: userId,
            approved: false,
            revoked: false
          });

        } catch (error) {
          console.error("tx Error: ", error)
        }
      }
    },
    [isConnected, signer]
  )

  return {
    requestInstuctor,
    isError,
    account
  }
}

export const useInstructorRequests = () => {

  const { address: account, connector, isConnected } = useAccount();
  const { data: signer, isError } = useSigner();
  const { auth }  = useContext(CustomContext);
  const [instructorRequests, setRequests] = useState([]);
  const [requestsValue, requestsLoading, requestsError] = useCollection(instructorRequestsQuery);
  //const pulse  = useBlockPulse();
  //const provider = getProvider();

  useEffect(() => {
    //console.log("requestsValue: ", requestsValue?.docs)
    if (requestsValue?.docs) {
      setRequests(requestsValue.docs.map(doc => doc.data()));
    } else {
      setRequests([]);
    }
    //console.log("instructorRequests: ", instructorRequests)
  }, [requestsValue])

  /*
  useEffect(() => {
    (async () => {
      if (provider && account) {
        try {
          console.log("pulse effect...")
          const contract = new Contract(REGISTRY_MUMBAI_ADDY, RhodesRegistry.abi, provider)

          const _instructorRequests = (await contract.instructorRequests(0)).toString()
          console.log("instructorRequests: ", _instructorRequests)

          //compare if requests have changed
          if (JSON.stringify(instructorRequests) !== JSON.stringify(_instructorRequests)) {
            console.log("setting instructorRequests...")
            setRequests(_instructorRequests);
          }
          
        } catch (e) {
          console.error(e)
        }
      }
    })()
  }, [provider, pulse])
*/

  const approveInstuctor = useCallback(
    async (_instructor) => {
      const isValid: boolean = Number(_instructor.toString()) > 0;
      const adminId: string = auth.id;
      const isAdmin: boolean = auth.authorities.includes('ROLE_ADMIN');
      //console.log("instructorRequest for id: ", _instructor, isValid)
      //console.log("admin: ", adminId, isAdmin)

      if (isConnected && signer && isAdmin && isValid) {
        try {
          const contract = new Contract(REGISTRY_MUMBAI_ADDY, RhodesRegistry.abi, signer)

          let _tx = await contract.connect(signer).approveInstructor(_instructor.toString())
          console.log("tx hash: ", _tx?.hash)
          let _receipt = await _tx.wait()
          console.log("tx receipt: ", _receipt)

          //check if tx !failed, _receipt.status == 1, success | 0, fail

          await updateDoc(doc(db, "users", _instructor.toString()), {
            instructor: true
          });

          await updateDoc(doc(db, "instructors", _instructor.toString()), {
            approved: true
          });

        } catch (error) {
          console.error("tx Error: ", error)
        }
      }
    },
    [isConnected, signer]
  )

  return {
    account,
    instructorRequests,
    requestsLoading,
    requestsError,
    approveInstuctor
  }
}

export const useCreateCourseTx = () => {

  const { address: account, isConnected } = useAccount();
  const { data: signer, isError } = useSigner();
  const { auth }  = useContext(CustomContext);
  const navigate = useNavigate();
  //add txInProgress boolean state

  const createCourse = useCallback(
    async (_course) => {
      console.log("_course: ", _course)
      const userId: string = auth.id;
      const isInstructor: boolean = auth.authorities.includes('ROLE_INSTRUCTOR');
      console.log("userId: ", userId, isInstructor)

      const { name, symbol, seats, cost } = _course;

      if (isConnected && signer && _course && isInstructor) {
        try {
          const contract = new Contract(REGISTRY_MUMBAI_ADDY, RhodesRegistry.abi, signer)

          let _tx = await contract.connect(signer).deployCourse(
            name.toString(),
            symbol.toString(),
            seats.toString(),
            cost.toString(),
            ""
          );
          console.log("tx hash: ", _tx?.hash)
          let _receipt = await _tx.wait()
          console.log("tx receipt: ", _receipt)
          console.log("tx status: ", _receipt.status)

          if (_receipt.status > 0) {
            const event = _receipt.events.find(x => x.event === "CourseDeployed");
            const courseId = event?.args?.course;
            console.log("new course address: ", courseId);

            await setDoc(doc(db, "courses", courseId), {
              ...removeNullUndefined(_course),
              id: courseId,
              image: false,
              instructor: userId,
              approved: false
            });

            const numSeats = Number(seats.toString())
            for (let i=1; i<=numSeats; i++ ) {
              const docRef = await addDoc(collection(db, "course-seats"), {
                course: courseId,
                seat: i.toString(),
                instructor: userId,
                student: '',
                registered: false,
                owner: '',
                completed: false,
                isComplete: false,
                grade: '',
              });
              console.log("Course Seat written with ID: ", docRef.id);
            }
            navigate('/instructor');
          }

        } catch (error) {
          console.error("tx Error: ", error)
        }
      }
    },
    [isConnected, signer]
  )

  return {
    createCourse,
    isError,
    account
  }
}

export const useCourseRequests = () => {

  const { address: account, connector, isConnected } = useAccount();
  const { data: signer, isError } = useSigner();
  const { auth }  = useContext(CustomContext);
  const [courseRequests, setRequests] = useState([]);
  const [requestsValue, courseRequestsLoading, courseRequestsError] = useCollection(courseRequestsQuery);

  useEffect(() => {
    console.log("requestsValue: ", requestsValue?.docs)
    if (requestsValue?.docs) {
      setRequests(requestsValue.docs.map(doc => doc.data()));
    } else {
      setRequests([]);
    }
    console.log("courseRequests: ", courseRequests)
  }, [requestsValue])

  const approveCourse = useCallback(
    async (_course) => {
      const isValid: boolean = !!_course.toString();
      const adminId: string = auth.id;
      const isAdmin: boolean = auth.authorities.includes('ROLE_ADMIN');
      //console.log("instructorRequest for id: ", _instructor, isValid)
      //console.log("admin: ", adminId, isAdmin)

      if (isConnected && signer && isAdmin && isValid) {
        try {
          const contract = new Contract(REGISTRY_MUMBAI_ADDY, RhodesRegistry.abi, signer)

          let _tx = await contract.connect(signer).approveCourse(_course.toString())
          console.log("tx hash: ", _tx?.hash)
          let _receipt = await _tx.wait()
          console.log("tx receipt: ", _receipt)
          console.log("tx status: ", _receipt.status)

          if (_receipt.status > 0) {
            await updateDoc(doc(db, "courses", _course.toString()), {
              approved: true
            });
          }

        } catch (error) {
          console.error("tx Error: ", error)
        }
      }
    },
    [isConnected, signer]
  )

  return {
    account,
    courseRequests,
    courseRequestsLoading,
    courseRequestsError,
    approveCourse
  }
}

export const useRhodesCourses = () => {
  const [courses, setCourses] = useState([]);
  const [coursesValue, coursesLoading, coursesError] = useCollection(coursesQuery);

  useEffect(() => {
    if (coursesValue?.docs?.length) {
      const _courses = coursesValue.docs.map(doc => doc.data());
      console.log("courses: ", _courses)
      _courses.forEach(async (each) => {
        each.seats = (await getDocs(courseSeatsQuery(each.id))).docs.map(doc => doc.data());
        each.seatsLeft = each.seats.filter(each => !each.owner).length
      })
      console.log("set courses: ", _courses)
      setCourses(_courses);
    } else {
      setCourses([]);
    }
  }, [coursesValue])

  return {
    courses,
    coursesLoading,
    coursesError
  }
}

export const useIntructorCourses = () => {
  const { auth }  = useContext(CustomContext);
  const userId: string = auth.id;
  const [courses, setCourses] = useState([]);
  const q = instructorCoursesQuery(userId)
  const [coursesValue, coursesLoading, coursesError] = useCollection(q);

  useEffect(() => {
    //console.log("coursesValue: ", coursesValue?.docs)
    if (coursesValue?.docs?.length) {
      const _courses = coursesValue.docs.map(doc => doc.data());
      //console.log("set courses: ", _courses)
      setCourses(_courses);
    } else {
      setCourses([]);
    }
  }, [coursesValue])

  return {
    courses,
    coursesLoading,
    coursesError
  }
}

export const useStudentSeats = () => {
  const { auth }  = useContext(CustomContext);
  const userId: string = auth.id;
  const { address: account } = useAccount();
  const [seats, setSeats] = useState([]);
  const q = studentCoursesQuery(account)
  const [seatsValue, seatsLoading, seatsError] = useCollection(q);

  useEffect(() => {
    console.log("seatsValue: ", seatsValue)
    if (seatsValue?.docs?.length) {
      const _seats = seatsValue.docs.map(doc => doc.data());
      console.log("set seats: ", _seats)
      setSeats(_seats);
    } else {
      setSeats([]);
    }
  }, [seatsValue])

  return {
    seats,
    seatsLoading,
    seatsError
  }
}

export const useStudentCourses = () => {
  const { auth }  = useContext(CustomContext);
  const userId: string = auth.id;
  const { address: account } = useAccount();
  const [courses, setCourses] = useState([]);
  const q = studentCoursesQuery(account)
  const [seatsValue, seatsLoading, seatsError] = useCollection(q);

  useEffect(() => {
    (async () => {
      if (seatsValue?.docs?.length) {
        const _seats = seatsValue.docs.map(doc => doc.data());
        console.log("seats found: ", _seats)
        const _courses = [];
        for (let i=0; i<_seats.length; i++) {
          const _course = (await getDoc(courseQuery(_seats[i].course))).data();
          _course.seat = _seats[i];
          _courses.push(_course);
        }
        console.log("set courses: ", _courses)
        setCourses(_courses);
      } else {
        setCourses([]);
      }
    })();
  }, [seatsValue]);

  return {
    courses,
    seatsLoading,
    seatsError
  }
}

export const useRhodesCourse = (courseId) => {
  const { address: account, isConnected } = useAccount();
  const { data: signer, isError } = useSigner();
  const { auth }  = useContext(CustomContext);
  const [course, setCourse] = useState({ name: '' });
  const [seat, setSeat] = useState({ seat: '', registered: false, completed: false });
  const [courseValue, courseLoading, courseError] = useDocument(courseQuery(courseId));

  useEffect(() => {
    (async () => {
      if (courseValue) {
        const _course = courseValue.data();
        _course.seats = (await getDocs(courseSeatsQuery(courseId))).docs.map(doc => doc.data());
        _course.author = _course.instructor
        console.log("set course: ", _course)
        setCourse(_course);

        const _studentSeats = _course.seats.filter(each => each.owner === account)
        console.log('studentSeats', _studentSeats)
        if (_studentSeats.length) {
          const _seat = _studentSeats[0];
          if (_seat.registered) {
            _seat.registered = true;
          } else {
            _seat.registered = false;
          }
          if (_seat.completed) {
            _seat.completed = true;
          } else {
            _seat.completed = false;
          }
          console.log("set seat: ", _seat)
          setSeat(_seat);
        } else {
          setSeat({ seat: '', registered: false, completed: false });
        }

      } else {
        setCourse({ name: '' });
      }
    })();
  }, [courseValue]);

  const mintSeat = useCallback(
    async (_course) => {
      console.log("_course: ", _course)
      const userId: string = auth.id;
      const isRegistered: boolean = Number(userId) > 0;
      console.log("userId: ", userId, isRegistered)

      if (isConnected && signer && _course && isRegistered) {
        try {
          const contract = new Contract(_course, RhodesCourse.abi, signer)

          const value = (await contract.fee()).toString();
          console.log("_cost: ", value);

          let _tx = await contract.connect(signer).mintSeat("1", { value })
          console.log("tx hash: ", _tx?.hash)
          let _receipt = await _tx.wait()
          console.log("tx receipt: ", _receipt)
          console.log("tx status: ", _receipt.status)

          if (_receipt.status > 0) {
            const event = _receipt.events.find(x => x.event === "Transfer");
            const seatId = event?.args?.tokenId?.toString();
            console.log("Minted new course seat Id: ", seatId);

            const _seats = await getDocs(courseSeatQuery(_course, seatId));
            const _seat = _seats.docs[0];
            console.log("Found seat doc: ", _seat.id);

            await updateDoc(doc(db, "course-seats", _seat.id), {
              owner: account,
              registered: false,
              completed: false,
              isComplete: false,
              grade: ''
            });
          }

        } catch (error) {
          console.error("tx Error: ", error)
        }
      }
    },
    [isConnected, signer]
  )

  const registerSeat = useCallback(
    async (_course, _seat) => {
      console.log("_course: ", _course)
      const userId: string = auth.id;
      const isRegistered: boolean = Number(userId) > 0;
      console.log("userId: ", userId, isRegistered)

      if (isConnected && signer && _course && isRegistered) {
        try {
          const contract = new Contract(_course, RhodesCourse.abi, signer)

          let _tx = await contract.connect(signer).registerSeat(_seat)
          console.log("tx hash: ", _tx?.hash)
          let _receipt = await _tx.wait()
          console.log("tx receipt: ", _receipt)
          console.log("tx status: ", _receipt.status)

          if (_receipt.status > 0) {
            console.log("Registering course seat Id: ", _seat);

            const _seats = await getDocs(courseSeatQuery(_course, _seat));
            const __seat = _seats.docs[0];
            console.log("Found seat doc: ", __seat.id);

            await updateDoc(doc(db, "course-seats", __seat.id), {
              registered: true,
              student: userId
            });
          }

        } catch (error) {
          console.error("tx Error: ", error)
        }
      }
    },
    [isConnected, signer]
  )

  return {
    course,
    courseLoading,
    courseError,
    seat,
    mintSeat,
    registerSeat
  }
}

export const useInstructorCourse = (courseId) => {
  const { address: account, isConnected } = useAccount();
  const { data: signer, isError } = useSigner();
  const { auth }  = useContext(CustomContext);
  const [course, setCourse] = useState({ name: '' });
  const [courseValue, courseLoading, courseError] = useDocument(courseQuery(courseId));

  useEffect(() => {
    (async () => {
      if (courseValue) {
        const _course = courseValue.data();
        _course.seats = (await getDocs(courseSeatsQuery(courseId))).docs.map(doc => doc.data());
        _course.author = _course.instructor
        console.log("set course: ", _course)
        setCourse(_course);

      } else {
        setCourse({ name: '' });
      }
    })();
  }, [courseValue]);

  const completeSeat = useCallback(
    async (_course, _seat, isComplete, grade) => {
      console.log("_course: ", _course)
      const userId: string = auth.id;
      const isRegistered: boolean = Number(userId) > 0;
      console.log("userId: ", userId, isRegistered)

      if (isConnected && signer && _course && isRegistered) {
        try {
          const contract = new Contract(_course, RhodesCourse.abi, signer)

          let _tx = await contract.connect(signer).completeSeat(_seat, isComplete, grade)
          console.log("tx hash: ", _tx?.hash)
          let _receipt = await _tx.wait()
          console.log("tx receipt: ", _receipt)
          console.log("tx status: ", _receipt.status)

          if (_receipt.status > 0) {
            console.log("Completing course seat Id: ", _seat);

            const _seats = await getDocs(courseSeatQuery(_course, _seat));
            const __seat = _seats.docs[0];
            console.log("Found seat doc: ", __seat.id);

            await updateDoc(doc(db, "course-seats", __seat.id), {
              completed: true,
              isComplete,
              grade
            });
          }

        } catch (error) {
          console.error("tx Error: ", error)
        }
      }
    },
    [isConnected, signer]
  )

  return {
    course,
    courseLoading,
    courseError,
    completeSeat
  }
}


let blocking
const useBlockPulse = () => {

  const [block, setBlock] = useState("");
  const [pulse, setPulse] = useState("");
  const provider = getProvider();

  const updateBlock = useCallback(
    _block => {
      //console.log("updateBlock", _block.toString());
      setBlock(_block.toString())
    },
    [setBlock]
  )

  useEffect(() => {
    if (provider) {
      console.log("setting up block watch...");
      provider.on("block", updateBlock)
      return () => provider.off("block", updateBlock)
    }
  }, [provider])

  useEffect(() => {
    if (blocking) {
      clearTimeout(blocking)
    }
    blocking = setTimeout(() => {
      blocking = false
      console.log("updatePulse", block);
      setPulse(block)
    }, "2000")
  }, [block])

  return pulse
}
