import { Button, Col, Form, Input, Row, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import { genders } from '../../models/Types';
import alertService from '../../services/AlertService';
import styles from './SignUpPage.module.scss';
import { SearchOutlined } from '@ant-design/icons';
import CustomContext from '../../contexts/CustomContext';
import rolesService from '../../services/RolesService';
import { User } from '../../models/Entities';
import userClient from '../../clients/UserClient';
import authService from '../../services/AuthService';
import { useRegisterUserTx } from '../../hooks/useRhodes';

/**
 * Returns the sign up page.
 * @returns the sign up page.
 */
const SignUpPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const context = useContext(CustomContext);
    const { auth, setAuth } = context;
    const [loading, setLoading] = useState<'loading'>();
    const { registerUser, isError, account } = useRegisterUserTx();

    // initialize app
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');

                if (auth && auth.initialized && rolesService.hasAnyRole(auth, ['ROLE_ADMIN'])) {
                    navigate('/instructor-requests');
                } else if (auth && auth.initialized && auth.mode === 'STUDENT') {
                    navigate('/student');
                } else if (auth && auth.initialized && auth.mode === 'INSTRUCTOR') {
                    navigate('/instructor');
                }

                // TODO: load page data (probably calling a client method )
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [auth, intl, navigate]);

    /*** METHODS ***/

    const save = async (values: any) => {
        try {
            setLoading('loading');
            let user: User = Object.assign({}, values);
            await registerUser(user);
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const genderOptions = genders.map((g) => (
        <Select.Option key={g} value={g}>
            {g}
        </Select.Option>
    ));

    return (
        <LayoutComponent title={<FormattedMessage id="signUp.title" />} menu="home" rightHeader={<Button icon={<SearchOutlined />} type="ghost"></Button>}>
            <div className={styles.panel}>
                <Form form={form} onFinish={save} colon={false} layout="vertical">
                    <Row gutter={[28, 0]}>
                        <Col span={8}>
                            <h1>
                                <FormattedMessage id="signUp.signUp" />
                            </h1>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Form.Item name="name" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}>
                                <Input placeholder={intl.formatMessage({ id: 'signUp.name' })} className="inputs" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Form.Item
                                name="email"
                                rules={[
                                    { required: true, message: <FormattedMessage id="status.mandatory" /> },
                                    {
                                        type: 'email',
                                        message: <FormattedMessage id="status.email.invalid" />
                                    }
                                ]}
                            >
                                <Input placeholder={intl.formatMessage({ id: 'signUp.email' })} className="inputs" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Form.Item name="phone" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}>
                                <Input placeholder={intl.formatMessage({ id: 'signUp.phone' })} className="inputs" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Form.Item name="grade">
                                <Input placeholder={intl.formatMessage({ id: 'signUp.grade' })} className="inputs" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Form.Item name="programInterest">
                                <Input placeholder={intl.formatMessage({ id: 'signUp.programInterest' })} className="inputs" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Form.Item name="state">
                                <Input placeholder={intl.formatMessage({ id: 'signUp.state' })} className="inputs" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Form.Item name="gender">
                                <Select placeholder={intl.formatMessage({ id: 'signUp.gender' })} className={styles.select}>
                                    {genderOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Form.Item name="ethnicity">
                                <Input placeholder={intl.formatMessage({ id: 'signUp.ethnicity' })} className="inputs" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Button type="primary" htmlType="submit" loading={loading === 'loading'} className="buttons">
                                <FormattedMessage id="signUp.signUp" />
                            </Button>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24} className={styles.terms}>
                            <FormattedMessage id="signUp.terms" />
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24} className={styles.terms}>
                            <FormattedMessage id="signUp.already" />
                            <Link to="/wallet">
                                <FormattedMessage id="signUp.connect" />
                            </Link>
                        </Col>
                    </Row>
                </Form>
            </div>
        </LayoutComponent>
    );
};
export default SignUpPage;
