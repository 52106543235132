import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useRhodesAuth } from '../../hooks/useRhodes';
import AdminCoursesPage from '../../pages/AdminPages/AdminCoursesPage/AdminCoursesPage';
import AdminInstructorsPage from '../../pages/AdminPages/AdminInstructorsPage/AdminInstructorsPage';
import AdminStudentsPage from '../../pages/AdminPages/AdminStudentsPage/AdminStudentsPage';
import InstructorRequestsPage from '../../pages/AdminPages/InstructorRequestsPage/InstructorRequestsPage';
import AssignmentCreationPage from '../../pages/AssignmentCreationPage/AssignmentCreationPage';
import AssignmentPage from '../../pages/AssignmentPage/AssignmentPage';
import BecomeInstructorPage from '../../pages/BecomeInstructorPage/BecomeInstructorPage';
import CourseCreationPage from '../../pages/CourseCreation/CourseCreationPage';
import CourseInstructorPage from '../../pages/CourseInstructorPage/CourseInstructorPage';
import CoursePage from '../../pages/CoursePage/CoursePage';
import CoursesPage from '../../pages/CoursesPage/CoursesPage';
import CourseActivePage from '../../pages/CourseActivePage/CourseActivePage';
import CreditsPage from '../../pages/CreditsPage/CreditsPage';
import ErrorPage from '../../pages/ErrorPage/ErrorPage';
import FaqPage from '../../pages/FaqPage/FaqPage';
import HelpPage from '../../pages/HelpPage/HelpPage';
import HomePage from '../../pages/HomePage/HomePage';
import HowItWorksPage from '../../pages/HowItWorksPage/HowItWorksPage';
import InstructorPage from '../../pages/InstructorPage/InstructorPage';
import IntroPage from '../../pages/IntroPage/IntroPage';
import MetaMaskPage from '../../pages/MetaMaskPage/MetaMaskPage';
import MyCoursesPage from '../../pages/MyCoursesPage/MyCoursesPage';
import SettingsPage from '../../pages/SettingsPage/SettingsPage';
import SignUpPage from '../../pages/SignUpPage/SignUpPage';
import StudentPage from '../../pages/StudentPage/StudentPage';
import WalletPage from '../../pages/WalletPage/WalletPage';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import CourseStudentPage from '../../pages/CourseStudentPage/CourseStudentPage';

const RoutesComponent: React.FC = () => {
    const { auth } = useRhodesAuth();
    return (
        <Routes>
            {/* TODO: add prefix by user type: e.g. /admin/... /student/... */}
            <Route element={<ProtectedRoute initialized={true} authorities={['ROLE_ADMIN']} />}>
                <Route path="/instructor-requests" element={<InstructorRequestsPage />} />
                <Route path="/admin-instructors" element={<AdminInstructorsPage />} />
                <Route path="/admin-students" element={<AdminStudentsPage />} />
                <Route path="/admin-courses" element={<AdminCoursesPage />} />
            </Route>

            {/* TODO: role change to student in order to see pages, must be changed to role instructor */}
            <Route element={<ProtectedRoute initialized={true} authorities={['ROLE_STUDENT']} />}>
                <Route path="/instructor" element={<InstructorPage />} />
                <Route path="/course-creation" element={<CourseCreationPage />} />
                <Route path="/course-instructor/:id" element={<CourseInstructorPage />} />
                <Route path="/assignments/:id" element={<AssignmentPage />} />
                <Route path="/assignment-creation" element={<AssignmentCreationPage />} />
                <Route path="/course-student" element={<CourseStudentPage />} />
            </Route>

            <Route element={<ProtectedRoute initialized={true} authorities={['ROLE_STUDENT']} />}>
                <Route path="/student" element={<StudentPage />} />
                <Route path="/mycourses" element={<MyCoursesPage />} />
                <Route path="/credits" element={<CreditsPage />} />
                <Route path="/course-active/:id" element={<CourseActivePage />} />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/courses" element={<CoursesPage />} />
                <Route path="/course/:id" element={<CoursePage />} />
                <Route path="/become-instructor" element={<BecomeInstructorPage />} />
            </Route>

            <Route element={<ProtectedRoute initialized={false} authorities={[]} redirectPath="/wallet" />}>
                <Route path="/signup" element={<SignUpPage />} />
            </Route>

            <Route path="/" element={<HomePage />} />
            <Route path="/intro" element={<IntroPage />} />
            <Route path="/metamask" element={<MetaMaskPage />} />
            <Route path="/wallet" element={<WalletPage />} />
            <Route path="/how-it-works" element={<HowItWorksPage />} />
            <Route path="/faq" element={<FaqPage />} />
            <Route path="/help" element={<HelpPage />} />

            <Route path="/error" element={<ErrorPage />} />
            <Route path="*" element={<ErrorPage />} />
        </Routes>
    );
};
export default RoutesComponent;
