import { ThemeConfig } from 'antd';

class ThemeComponent {
    getTheme = () => {
        const appTheme: ThemeConfig = {
            token: {
                /* colorPrimary: '#5B6EC6', */
                colorLink: '#000',
                colorLinkActive: '#000',
                colorLinkHover: '#000',
                fontFamily: 'Lato',
                fontSize: 16
            },
            components: {
                Button: {
                    colorPrimary: 'rgba(131, 42, 144, 1)',
                    colorPrimaryBg: 'rgba(131, 42, 144, 1)',
                    colorPrimaryBgHover: 'rgba(131, 42, 144, 1)',
                    colorPrimaryActive: 'rgba(131, 42, 144, 1)',
                    colorPrimaryHover: 'rgba(131, 42, 144, 1)',
                    colorBgBase: 'transparent'
                },
                Menu: {
                    colorItemBgSelected: '#fff',
                    colorItemTextSelected: '#000',
                    colorItemText: '#fff !important',
                    radiusItem: 12
                }
                /* Layout: {
                    colorBgBody: '#F4F5FA'
                },
                Card: {
                    colorBgContainer: '#fff',
                    borderRadiusLG: 12,
                    borderRadiusSM: 12,
                    borderRadiusXS: 12
                },
                Menu: {
                    colorItemBgSelected: '#5B6EC6',
                    colorItemTextSelected: '#fff',
                    radiusItem: 12
                },
                Input: {
                    colorBgContainer: 'rgba(239, 241, 249, 0.6)',
                    colorBorder: 'transparent'
                },
                Select: {
                    colorBgContainer: 'rgba(239, 241, 249, 0.6)',
                    colorBorder: 'transparent'
                },
                Tabs: {
                    colorPrimary: 'rgba(43, 47, 50, 1)',
                    margin: 30
                } */
            }
        };

        return appTheme;
    };
}

const themeComponent: ThemeComponent = new ThemeComponent();
export default themeComponent;
