import Icon, { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Col, Form, Image, Input, Row, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Dragger from 'antd/es/upload/Dragger';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../contexts/CustomContext';
import { useCreateCourseTx } from '../../hooks/useRhodes';
import { CustomAuth } from '../../models/Entities';
import { categories } from '../../models/Types';
import { ReactComponent as dotsIcon } from '../../resources/images/dots-black-icon.svg';
import folderIcon from '../../resources/images/folder-icon.svg';
import alertService from '../../services/AlertService';
import styles from './CourseCreationPage.module.scss';

/**
 * Returns course creation page.
 * @returns course creation page.
 */
const CourseCreationPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;
    const [loading, setLoading] = useState<'loading'>();
    const { createCourse } = useCreateCourseTx();

    // initialize app
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [auth.id, intl]);

    /*** METHODS ***/

    /*** VISUAL ***/

    const categoryOptions = categories.map((c) => (
        <Select.Option key={c} value={c}>
            {c}
        </Select.Option>
    ));

    return (
        <LayoutComponent
            title={<FormattedMessage id="courseCreation.title" />}
            menu="courseCreation"
            centerHeader={
                <div className={styles.pageTitle}>
                    <FormattedMessage id="courseCreation.create" />
                </div>
            }
            rightHeader={<Button icon={<Icon component={dotsIcon} className={styles.icon} />} type="ghost"></Button>}
        >
            <Form form={form} onFinish={createCourse} colon={false} layout="vertical">
                <div className={styles.sectionTitle}>
                    <FormattedMessage id="courseCreation.courseTitle" />
                </div>
                <div className={styles.sectionDesc}>
                    <FormattedMessage id="common.loremIpsum" />
                </div>
                <Row gutter={[28, 0]}>
                    <Col span={24}>
                        <Form.Item name="name" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}>
                            <Input placeholder={intl.formatMessage({ id: 'courseCreation.courseTitle' })} className="inputs" />
                        </Form.Item>
                    </Col>
                </Row>
                <div className={styles.sectionTitle}>
                    <FormattedMessage id="courseCreation.selectCategory" />
                </div>
                <div className={styles.sectionDesc}>
                    <FormattedMessage id="common.loremIpsum" />
                </div>
                <Row gutter={[28, 0]}>
                    <Col span={24}>
                        <Form.Item name="category" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}>
                            <Select placeholder={intl.formatMessage({ id: 'courseCreation.category' })} className={styles.category}>
                                {categoryOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <div className={styles.sectionTitle}>
                    <FormattedMessage id="courseCreation.description" />
                </div>
                <div className={styles.sectionDesc}>
                    <FormattedMessage id="common.loremIpsum" />
                </div>
                <Row gutter={[28, 0]}>
                    <Col span={24}>
                        <Form.Item name="description" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}>
                            <TextArea
                                placeholder={intl.formatMessage({ id: 'courseCreation.addDescription' })}
                                draggable={false}
                                className={styles.courseDesc}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <div className={styles.sectionTitle}>
                    <FormattedMessage id="courseCreation.image" />
                </div>
                <div className={styles.sectionDesc}>
                    <FormattedMessage id="common.loremIpsum" />
                </div>
                <Row gutter={[28, 0]}>
                    <Col span={24}>
                        <Form.Item name="image">
                            <Dragger className={styles.dragger}>
                                <Image src={folderIcon} preview={false} className={styles.folder} />
                            </Dragger>
                            <Button type="primary" htmlType="submit" loading={loading === 'loading'} className="buttons">
                                <FormattedMessage id="courseCreation.button.uploadFile" tagName="span" />
                            </Button>
                            <div className={styles.fileType}>
                                Guidance on asset resolution and files types should go here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
                                feugiat nisl at erat convallis, eu dapibus quam commodo. Praesent lacus erat.
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
                <div className={styles.sectionTitle}>
                    <FormattedMessage id="courseCreation.moreInfo" />
                </div>
                <Row gutter={[28, 0]}>
                    <Col span={24}>
                        <Form.Item name="symbol" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}>
                            <Input placeholder={intl.formatMessage({ id: 'courseCreation.courseSymbol' })} className="inputs" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={[28, 0]}>
                    <Col span={24}>
                        <Form.Item name="seats" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}>
                            <Input placeholder={intl.formatMessage({ id: 'courseCreation.courseNumSeats' })} className="inputs" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col span={24}>
                        <Form.Item name="cost" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}>
                            <Input placeholder={intl.formatMessage({ id: 'courseCreation.courseSeatCostInMatic' })} className="inputs" />
                        </Form.Item>
                    </Col>
                </Row>

                <div className={styles.buttons}>
                    <Button icon={<ArrowLeftOutlined />} className={styles.cancel} onClick={() => navigate(-1)}></Button>
                    <Button type="primary" htmlType="submit" loading={loading === 'loading'} className="buttons">
                        <FormattedMessage id="button.save" tagName="span" />
                    </Button>
                </div>
            </Form>
        </LayoutComponent>
    );
};
export default CourseCreationPage;
