import { Button, Col, Form, Image, Input, Row, Switch, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRequestInstructorTx } from '../../hooks/useRhodes';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import avatarPencilImage from '../../resources/images/avatar-pencil.svg';
import alertService from '../../services/AlertService';
import styles from './BecomeInstructorPage.module.scss';

/**
 * Returns become an instructor page.
 * @returns become an instructor page.
 */
const BecomeInstructorPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'loading'>();
    const [form] = Form.useForm();
    const { requestInstuctor } = useRequestInstructorTx();

    // initialize app
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl]);

    /*** METHODS ***/

    /*** VISUAL ***/

    return (
        <LayoutComponent title={<FormattedMessage id="becomeInstructor.title" />} menu="becomeInstructor">
            <div className={styles.panel}>
                <div className={styles.title}>
                    <FormattedMessage id="becomeInstructor.registration" />
                </div>
                <div className={styles.description}>
                    <FormattedMessage id="common.loremIpsum" />
                </div>
                <Form form={form} onFinish={requestInstuctor} colon={false} layout="vertical">
                    <div className={styles.basicInfo}>
                        <FormattedMessage id="becomeInstructor.basic" />
                    </div>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Form.Item name="dateOfBirth" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}>
                                <Input placeholder={intl.formatMessage({ id: 'becomeInstructor.dateOfBirth' })} className="inputs" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Form.Item name="streetAddress" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}>
                                <Input placeholder={intl.formatMessage({ id: 'becomeInstructor.streetAddress' })} className="inputs" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Form.Item name="city" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}>
                                <Input placeholder={intl.formatMessage({ id: 'becomeInstructor.city' })} className="inputs" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Form.Item name="facebook">
                                <Input placeholder={intl.formatMessage({ id: 'becomeInstructor.facebook' })} className="inputs" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Form.Item name="twitter">
                                <Input placeholder={intl.formatMessage({ id: 'becomeInstructor.twitter' })} className="inputs" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className={styles.upload}>
                        <Form.Item name="avatar">
                            <Upload listType="picture-circle" showUploadList={false}>
                                <Image src={avatarPencilImage} preview={false} />
                            </Upload>
                        </Form.Item>
                    </div>
                    <div className={styles.basicInfo}>
                        <FormattedMessage id="becomeInstructor.experience" />
                    </div>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Form.Item name="yearsOfEducation" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}>
                                <Input placeholder={intl.formatMessage({ id: 'becomeInstructor.yearsOfEducation' })} className="inputs" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Form.Item name="levelOfEducation" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}>
                                <Input placeholder={intl.formatMessage({ id: 'becomeInstructor.levelOfEducation' })} className="inputs" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Form.Item name="degrees" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}>
                                <Input placeholder={intl.formatMessage({ id: 'becomeInstructor.degrees' })} className="inputs" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Form.Item name="yearsOfExp" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}>
                                <Input placeholder={intl.formatMessage({ id: 'becomeInstructor.yearsOfExp' })} className="inputs" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Form.Item name="certifications" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}>
                                <Input placeholder={intl.formatMessage({ id: 'becomeInstructor.certifications' })} className="inputs" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Form.Item name="programOfInterest" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}>
                                <Input placeholder={intl.formatMessage({ id: 'becomeInstructor.programOfInterest' })} className="inputs" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Form.Item name="previouslyTaught" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}>
                                <Input placeholder={intl.formatMessage({ id: 'becomeInstructor.previouslyTaught' })} className="inputs" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Form.Item name="fluentLanguages" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}>
                                <Input placeholder={intl.formatMessage({ id: 'becomeInstructor.fluentLanguages' })} className="inputs" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <div className={styles.basicInfo}>
                                <FormattedMessage id="becomeInstructor.felony" />
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label={<FormattedMessage id="becomeInstructor.yesNo" />}
                                name="felony"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Switch></Switch>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <div className={styles.basicInfo}>
                                <FormattedMessage id="becomeInstructor.revoked" />
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label={<FormattedMessage id="becomeInstructor.yesNo" />}
                                name="revoked"
                                rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                            >
                                <Switch></Switch>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[28, 0]}>
                        <Col span={24}>
                            <Button type="primary" htmlType="submit" loading={loading === 'loading'} className="buttons">
                                <FormattedMessage id="becomeInstructor.button.submit" tagName="span" />
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </LayoutComponent>
    );
};
export default BecomeInstructorPage;
