import { SearchOutlined } from '@ant-design/icons';
import { Button, Image } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../contexts/CustomContext';
import rhodesAcademyImage from '../../resources/images/rhodes-academy-home.svg';
import alertService from '../../services/AlertService';
import rolesService from '../../services/RolesService';
import styles from './HomePage.module.scss';

/**
 * Returns the home page.
 * @returns the home page.
 */
const HomePage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const navigate = useNavigate();
    const context = useContext(CustomContext);
    const { auth } = context;
    const [loading, setLoading] = useState<'loading'>();

    // initialize app
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');

                if (auth && auth.initialized && rolesService.hasAnyRole(auth, ['ROLE_ADMIN'])) {
                    navigate('/instructor-requests');
                } else if (auth && auth.initialized && auth.mode === 'STUDENT') {
                    navigate('/student');
                } else if (auth && auth.initialized && auth.mode === 'INSTRUCTOR') {
                    navigate('/instructor');
                } else if (auth && !auth.initialized) {
                    navigate('/signup');
                }

                // TODO: load page data (probably calling a client method )
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [auth, intl, navigate]);

    /*** METHODS ***/

    /*** VISUAL ***/

    return (
        <LayoutComponent title={<FormattedMessage id="home.title" />} menu="home" rightHeader={<Button icon={<SearchOutlined />} type="ghost"></Button>}>
            <div className={styles.panel}>
                <Image src={rhodesAcademyImage} preview={false} />

                <h1 className={styles.title}>
                    <FormattedMessage id="home.text1" />
                </h1>

                <div className={styles.description}>
                    <FormattedMessage id="home.text2" />
                </div>

                <div className={styles.buttons}>
                    <Link to="/intro">
                        <Button type="primary" className="buttons">
                            <FormattedMessage id="home.start" />
                        </Button>
                    </Link>
                </div>

                <div className={styles.existing}>
                    <FormattedMessage id="home.existing" />
                </div>

                <Link to="/wallet">
                    <Button className="buttons">
                        <FormattedMessage id="home.connect" />
                    </Button>
                </Link>
            </div>
        </LayoutComponent>
    );
};
export default HomePage;
