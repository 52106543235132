import Icon from '@ant-design/icons';
import { Button, Collapse, Image, Progress } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import courseClient from '../../clients/CourseClient';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../contexts/CustomContext';
import { Course, CustomAuth } from '../../models/Entities';
import avatarImage from '../../resources/images/avatar-example1.svg';
import chatIcon from '../../resources/images/chat-icon.svg';
import cupIcon from '../../resources/images/cup-icon.svg';
import { ReactComponent as dotsIcon } from '../../resources/images/dots-black-icon.svg';
import hexagonGreenImage from '../../resources/images/hexagon-green.svg';
import alertService from '../../services/AlertService';
import styles from './CourseStudentPage.module.scss';
import { ReactComponent as downloadIcon } from '../../resources/images/download-green-icon.svg';
import { ReactComponent as folderIcon } from '../../resources/images/folder-icon.svg';
import watchIcon from '../../resources/images/watch-icon.svg';

const { Panel } = Collapse;

/**
 * Returns the course student page.
 * @returns the course student page.
 */
const CourseStudentPage: React.FC = () => {
    /*** HOOKS ***/

    /*** METHODS ***/

    /*** VISUAL ***/

    return (
        <LayoutComponent
            title={<FormattedMessage id="courseStudent.title" />}
            menu="courseStudent"
            rightHeader={<Button icon={<Icon component={dotsIcon} className={styles.icon} />} type="ghost"></Button>}
        >
            <StudentComponent />
        </LayoutComponent>
    );
};
export default CourseStudentPage;

/**
 * Returns the student component.
 * @returns the student component
 */
const StudentComponent = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'loading'>();
    const [courses, setCourses] = useState<Course[]>([]);
    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;

    /*** EFFECTS ***/

    // initialize app
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');

                // TODO: load page data (probably calling a client method)
                const courses = await courseClient.listInstructorCourses(auth.id);
                setCourses(courses);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [auth.id, intl]);

    /*** METHODS ***/

    /*** COMPONENTS */

    return (
        <>
            <div className={styles.studentBackground}>
                <div className={styles.personal}>
                    <div className={styles.information}>
                        <Image src={avatarImage} preview={false} />
                        <div className={styles.info}>
                            <div className={styles.name}>{auth.name}</div>
                            <div className={styles.description}>
                                <FormattedMessage id="courseStudent.student" />
                            </div>
                        </div>
                    </div>
                    <div>
                        <Image src={chatIcon} preview={false} />
                        <Image src={cupIcon} preview={false} className={styles.cup} />
                    </div>
                </div>
                <div className={styles.details}>
                    <div className={styles.coursePerformance}>
                        <div className={styles.performance}>
                            <FormattedMessage id="courseStudent.performance" />
                        </div>
                        <div className={styles.percentage}>
                            <div>
                                <FormattedMessage id={'courseStudent.lessons'} values={{ current: 22, total: 33 }} />
                            </div>
                            <div>
                                <FormattedMessage id={'courseStudent.percentage'} values={{ percentage: 75 }} />
                            </div>
                        </div>
                        <Progress percent={75} showInfo={false} className={styles.progressBar} />
                    </div>
                    <div className={styles.imageContainer}>
                        <Image src={hexagonGreenImage} preview={false} className={styles.hexagon} />
                        <div className={styles.credits}>
                            {10}
                            <div className={styles.mc}>
                                <FormattedMessage id="courseStudent.avg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.submissions}>
                <FormattedMessage id="courseStudent.assignmentSubmissions" />
            </div>

            <Collapse className={styles.collapse} defaultActiveKey={1}>
                <Panel
                    header={
                        <div className={styles.header}>
                            <div className={styles.one}>Managing Expectations in the Workplace</div>
                            <div className={styles.two}>Lesson 1: Working with Senior Management</div>
                            <div className={styles.three}>
                                <Image src={watchIcon} preview={false} /> Submitted 04/03/2023 at 9:59am
                            </div>
                        </div>
                    }
                    key={1}
                >
                    <div className={styles.file}>
                        <div className={styles.image}>
                            <Icon component={folderIcon} className={styles.folder} />
                            <div className={styles.fileName}>
                                <div className={styles.name}>sdriver.pdf</div>
                                <div className={styles.size}>12.9 MB</div>
                            </div>
                        </div>
                        <Button icon={<Icon component={downloadIcon} className={styles.download} />} type="ghost"></Button>
                    </div>
                    <div className={styles.grade}>
                        <div className={styles.content}>
                            <div className={styles.smallImageContainer}>
                                <Image src={hexagonGreenImage} preview={false} className={styles.hexagonSmall} />
                                <div className={styles.number}>89</div>
                            </div>
                            <div className={styles.gradeText}>
                                <div className={styles.title}>Assignment Grade</div>
                                <div className={styles.date}>03/05/2023 at 9:22pm</div>
                            </div>
                        </div>
                        <div className={styles.message}>Hello Marisson!</div>
                    </div>
                </Panel>
            </Collapse>
        </>
    );
};
