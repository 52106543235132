import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Image } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useConnect } from 'wagmi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import metamaskImage from '../../resources/images/metamask.svg';
import alertService from '../../services/AlertService';
import styles from './MetaMaskPage.module.scss';

/**
 * Returns the metamask page.
 * @returns the metamask page.
 */
const MetaMaskPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const { connectors } = useConnect();
    const [loading, setLoading] = useState<'loading'>();
    const connector = connectors.length > 0 ? connectors[0] : undefined;
    const isConnectorReady = connector && !!connector.ready;

    // initialize app
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl]);

    /*** METHODS ***/

    /*** VISUAL ***/

    return (
        <LayoutComponent
            title={<FormattedMessage id="metaMask.title" />}
            menu="home"
            leftHeader={
                <Link to="/intro">
                    <Button icon={<ArrowLeftOutlined />} type="ghost"></Button>
                </Link>
            }
            centerHeader={
                <div className="pageTitle">
                    <FormattedMessage id="metaMask.step" />
                </div>
            }
        >
            <div className={styles.panel}>
                <div className={styles.image}>
                    <Image src={metamaskImage} preview={false} />
                </div>

                <h1 className={styles.title}>
                    <FormattedMessage id="metaMask.text1" />
                </h1>

                <div className={styles.description}>
                    <FormattedMessage id="metaMask.text2" />
                </div>

                <div className={styles.buttons}>
                    <a href="https://metamask.io/download.html">
                        <Button type="primary" className="buttons">
                            <FormattedMessage id="metaMask.download" /> {isConnectorReady && <FormattedMessage id="metaMask.installed" />}
                        </Button>
                    </a>

                    <div className={styles.image}>
                        <FormattedMessage id="metaMask.already" />
                    </div>

                    <Link to="/wallet">
                        <Button className="buttons">
                            <FormattedMessage id="metaMask.wallet" />
                        </Button>
                    </Link>
                </div>
            </div>
        </LayoutComponent>
    );
};
export default MetaMaskPage;
