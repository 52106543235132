import { Credit } from '../models/Entities';

/**
 * This class represents the credit client.
 */
class CreditClient {
    /**
     * Retrieves the credits of a student.
     * @param userId the user id
     * @returns the credits
     */
    getStudentCredits = async (userId?: string): Promise<Credit[]> => {
        const courses: Credit[] = [
            {
                id: '1',
                total: 2,
                course: 'Course 1'
            },
            {
                id: '2',
                total: 4,
                course: 'Course 2'
            },
            {
                id: '3',
                total: 5,
                course: 'Course 3'
            }
        ];

        return courses;
    };
}

const creditClient: CreditClient = new CreditClient();
export default creditClient;
