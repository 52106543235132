import { Card, Image, List, Progress, Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import courseClient from '../../clients/CourseClient';
import { useStudentCourses } from '../../hooks/useRhodes';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../contexts/CustomContext';
import { Course, CustomAuth } from '../../models/Entities';
import creditsIcon from '../../resources/images/course-credits-icon.svg';
import exampleImage from '../../resources/images/example1.png';
import exampleTwoImage from '../../resources/images/example2.png';
import exampleThreeImage from '../../resources/images/example3.png';
import hexagonIcon from '../../resources/images/hexagon-icon.svg';
import alertService from '../../services/AlertService';
import styles from './MyCoursesPage.module.scss';
import './MyCoursesPage.css';
/**
 * Returns my courses page.
 * @returns my courses page.
 */
const MyCoursesPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    //const [loading, setLoading] = useState<'loading'>();
    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;
    //const [courses, setCourses] = useState<Course[]>([]);
    const { courses, seatsLoading: loading, seatsError: error } = useStudentCourses();
/*
    // initialize app
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');

                // TODO: load page data (probably calling a client method)
                const courses = await courseClient.listStudentCourses(auth.id);
                setCourses(courses);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [auth.id, intl]);
*/
    /*** METHODS ***/

    /*** VISUAL ***/

    const items = [
        {
            label: <FormattedMessage id="myCourses.all" />,
            key: 'all',
            children: (
                <List
                    itemLayout="vertical"
                    dataSource={courses}
                    loading={loading}
                    renderItem={(course: any) => {
                        return (
                            <List.Item key={course.id}>
                                <Link to={`/course-active/${course.id}`}>
                                    <Card
                                        title={
                                            <div className={styles.title}>
                                                <Image src={exampleTwoImage} preview={false} className={styles.image} />
                                                <div className={styles.info}>
                                                    <div className={styles.course}>{course?.name}</div>
                                                    <div className={styles.author}>{course?.instuctor}</div>
                                                </div>
                                            </div>
                                        }
                                        className={styles.allCard}
                                    >
                                        <div className={styles.details}>
                                            <span>
                                                <span className={styles.marketValue}>
                                                    <FormattedMessage id="myCourses.marketValue" />
                                                </span>{course?.cost}
                                                <Image src={creditsIcon} preview={false} /> <span className={styles.credits}>{course.credits}</span>
                                            </span>
                                            <span className={styles.status}>{course?.status}</span>
                                        </div>
                                    </Card>
                                </Link>
                            </List.Item>
                        );
                    }}
                />
            )
        },
        {
            label: <FormattedMessage id="myCourses.owned" />,
            key: 'owned',
            children: courses.filter((each: any) => !each.seat.registered).map((course: any) => {
                return (
                    <Link to={`/course-active/${course?.id}`} key={course?.id}>
                        <div className={styles.registeredCard}>
                            <Image src={exampleThreeImage} preview={false} />
                            <div className={styles.titleHeader}>
                                <div className={styles.title}>{course?.name}</div>
                                <div className={styles.start}>
                                    <FormattedMessage id="myCourses.start" />
                                </div>
                            </div>
                            <div className={styles.author}>{course?.instructor}</div>
                        </div>
                    </Link>
                );
            })
        },
        {
            label: <FormattedMessage id="myCourses.registered" />,
            key: 'registered',
            children: courses.filter((each: any) => each.seat.registered && !each.seat.completed).map((course: any) => {
                return (
                    <Link to={`/course-active/${course?.id}`} key={course?.id}>
                        <div className={styles.registeredCard}>
                            <Image src={exampleThreeImage} preview={false} />
                            <div className={styles.titleHeader}>
                                <div className={styles.title}>{course?.name}</div>
                                <div className={styles.start}>
                                    <FormattedMessage id="myCourses.start" />
                                </div>
                            </div>
                            <div className={styles.author}>{course?.instructor}</div>
                        </div>
                    </Link>
                );
            })
        },
        {
            label: <FormattedMessage id="myCourses.inProgress" />,
            key: 'inProgress',
            children: courses.filter((each: any) => each.seat.registered && !each.seat.completed).map((course: any) => {
                const percentage = course?.currentLessons && course?.totalLessons ? Math.round((course?.currentLessons * 100) / course.totalLessons) : 0;
                return (
                    <Link to={`/course-active/${course?.id}`} key={course?.id}>
                        <div className={styles.progressCard}>
                            <Image src={exampleImage} preview={false} />
                            <div className={styles.titleHeader}>
                                <div className={styles.title}>{course?.name}</div>
                                <div className={styles.discord}>
                                    <FormattedMessage id="myCourses.discord" />
                                </div>
                            </div>
                            {course.currentLessons && course.totalLessons && (
                                <>
                                    <div className={styles.details}>
                                        <div>
                                            <FormattedMessage
                                                id={'myCourses.lessons'}
                                                values={{ current: course?.currentLessons, total: course?.totalLessons }}
                                            />
                                        </div>
                                        <div>
                                            <FormattedMessage id={'myCourses.percentage'} values={{ percentage: percentage }} />
                                        </div>
                                    </div>
                                    <Progress percent={percentage} showInfo={false} className={styles.progressBar} />
                                </>
                            )}
                        </div>
                    </Link>
                );
            })
        },
        {
            label: <FormattedMessage id="myCourses.completed" />,
            key: 'completed',
            children: courses.filter((each: any) => !!each.seat.completed).map((course: any) => {
                return (
                    <Link to={`/course-active/${course?.id}`} key={course?.id}>
                        <div className={styles.registeredCard}>
                            <Image src={exampleThreeImage} preview={false} />
                            <div className={styles.titleHeader}>
                                <div className={styles.title}>{course?.name}</div>
                                <div className={styles.start}>
                                    <FormattedMessage id="myCourses.start" />
                                </div>
                            </div>
                            <div className={styles.author}>{course?.instructor}</div>
                        </div>
                    </Link>
                );
            })
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="myCourses.title" />}
            menu="myCourses"
            rightHeader={
                <Link to={`/credits`}>
                    <div className={styles.imageContainer}>
                        <div className={styles.credits}>12</div>
                        <Image src={hexagonIcon} preview={false} />
                    </div>
                </Link>
            }
            subHeader={
                <h1 className={styles.myCourses}>
                    <FormattedMessage id="myCourses.myCourses" />
                </h1>
            }
            lilacHeader
        >
            <div className={styles.panel}>
                <Tabs items={items} className={styles.tabs} />
            </div>
        </LayoutComponent>
    );
};
export default MyCoursesPage;
