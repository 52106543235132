import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useInstructorRequests, useCourseRequests } from '../../../hooks/useRhodes';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import alertService from '../../../services/AlertService';

/**
 * Returns instructor requests page.
 * @returns instructor requests page.
 */
const InstructorRequestsPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'loading'>();
    const {
        instructorRequests,
        requestsLoading,
        requestsError,
        approveInstuctor
    } = useInstructorRequests();

    const {
        courseRequests,
        courseRequestsLoading,
        courseRequestsError,
        approveCourse
    } = useCourseRequests();

    // initialize app
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl]);

    /*** METHODS ***/

    /*** VISUAL ***/

    return (
        <LayoutComponent title={<FormattedMessage id="instructorRequests.title" />} menu="instructorRequests">
            {requestsError && <strong>Instructor Requests Error: {JSON.stringify(requestsError)}</strong>}
            {requestsLoading && <span>Instructor Requests Loading...</span>}
            {!requestsLoading && <span>Instructor Requests: </span>}
            {!requestsLoading && !!instructorRequests && instructorRequests.map(({id}) => (
              <button key={id} onClick={() => approveInstuctor(id)}>{`Approve User ID: ${id}`}</button>
            ))}
            <br />
            {!courseRequestsLoading && <span>Course Approval Requests: </span>}
            {!courseRequestsLoading && !!courseRequests && courseRequests.map(({id, name}) => (
              <button key={id} onClick={() => approveCourse(id)}>{`Approve Course: ${name}`}</button>
            ))}
            <br />
        </LayoutComponent>
    );
};
export default InstructorRequestsPage;
