import { ConfigProvider, message } from 'antd';
import enUS from 'antd/lib/locale/en_US';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useAccount } from 'wagmi';
import HeadMetadata from '../../components/HeadMetadata';
import RoutesComponent from '../../components/RoutesComponent/RoutesComponent';
import appThemeComponent from '../../components/ThemeComponent/ThemeComponent';
import CustomContext from '../../contexts/CustomContext';
import messagesEn from '../../i18n/en.json';
import { CustomAuth } from '../../models/Entities';
import authService from '../../services/AuthService';

/**
 * Returns the user app.
 * @returns the user app.
 */
const UserApp: React.FC = () => {
    /*** HOOKS ***/

    // create context
    const { isConnected, address } = useAccount();
    const [auth, setAuth] = useState<CustomAuth>();
    const context = { auth, setAuth };
    const [initialized, setInitialized] = useState<boolean>(false);

    // initialize app
    useEffect(() => {
        const init = async () => {
            // set-up antd configuration
            message.config({
                maxCount: 1
            });

            // set-up locale
            moment.locale('en');

            // set-up current auth
            if (isConnected && address) {
                const auth = await authService.get(address);
                setAuth(auth);
            }
            setInitialized(true);
        };
        init();
    }, [address, isConnected]);

    /*** VISUAL ***/

    if (initialized) {
        return (
            <ConfigProvider locale={enUS} theme={appThemeComponent.getTheme()}>
                <CustomContext.Provider value={context}>
                    <IntlProvider locale="en-US" defaultLocale="en-US" messages={messagesEn}>
                        <HeadMetadata />
                        <RoutesComponent />
                    </IntlProvider>
                </CustomContext.Provider>
            </ConfigProvider>
        );
    } else {
        return <></>;
    }
};
export default UserApp;
