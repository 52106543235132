import Icon, { ArrowRightOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Image, List, Tooltip } from 'antd';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../contexts/CustomContext';
import { useIntructorCourses } from '../../hooks/useRhodes';
import { CustomAuth } from '../../models/Entities';
import avatarImage from '../../resources/images/avatar-example1.svg';
import { ReactComponent as dotsIcon } from '../../resources/images/dots-black-icon.svg';
import exampleTwoImage from '../../resources/images/example2.png';
import instructorDashboardImage from '../../resources/images/instructor-dashboard.svg';
import rightArrowIcon from '../../resources/images/right-arrow-white-icon.svg';
import styles from './InstructorPage.module.scss';

/**
 * Returns the instructor page.
 * @returns the instructor page.
 */
const InstructorPage: React.FC = () => {
    /*** HOOKS ***/

    const [mode, setMode] = useState<'dashboard' | 'home'>('dashboard');

    /*** METHODS ***/

    //TODO: display "home" component if instructor's number of courses is > 0

    /*** VISUAL ***/

    return (
        <LayoutComponent
            title={<FormattedMessage id="home.title" />}
            menu="instructor"
            lilacContent={mode === 'dashboard'}
            rightHeader={<Button icon={<Icon component={dotsIcon} className={styles.icon} />} type="ghost"></Button>}
        >
            {mode === 'dashboard' ? <DashboardComponent changeMode={setMode} /> : <CoursesComponent />}
        </LayoutComponent>
    );
};
export default InstructorPage;

/**
 * Returns the dashboard component.
 * @returns the dashboard component
 */
const DashboardComponent = (props: DashboardProps): React.ReactElement => {
    const { changeMode } = props;
    /*** HOOKS ***/

    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;

    /*** EFFECTS ***/

    /*** METHODS ***/

    /*** COMPONENTS */

    return (
        <>
            <div className={styles.instructor}>
                <Image src={avatarImage} preview={false} />
                <div className={styles.info}>
                    <div className={styles.name}>{auth.name}</div>
                    <div className={styles.details}>Class Category 1, Class Category 2</div>
                </div>
            </div>
            <div className={styles.createCourse}>
                <div className={styles.info}>
                    <div className={styles.ready}>
                        <FormattedMessage id="instructor.ready" />
                    </div>
                    <div className={styles.create}>
                        <FormattedMessage id="instructor.create" />
                    </div>
                </div>
                <Link to="/course-creation">
                    <Image src={rightArrowIcon} preview={false} className={styles.image} />
                </Link>
            </div>
            <div className={styles.panel}>
                <Avatar src={instructorDashboardImage} className={styles.avatar} />

                <h1 className={styles.title}>
                    <FormattedMessage id="instructor.welcome" />
                </h1>

                <div className={styles.description}>
                    <FormattedMessage id="home.text2" />
                </div>

                <div className={styles.next}>
                    <Button icon={<ArrowRightOutlined />} shape="circle" className={styles.button} onClick={() => changeMode('home')}></Button>
                </div>
            </div>
        </>
    );
};

interface DashboardProps {
    changeMode: (mode: 'dashboard' | 'home') => void;
}

/**
 * Returns the courses component.
 * @returns the courses component
 */
const CoursesComponent = (): React.ReactElement => {
    /*** HOOKS ***/

    const intl = useIntl();
    //const [loading, setLoading] = useState<'loading'>();
    //const [courses, setCourses] = useState<Course[]>([]);
    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;
    const { courses, coursesLoading: loading, coursesError: error } = useIntructorCourses();

    /*** EFFECTS ***/

    /*
    // initialize app
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');

                // TODO: load page data (probably calling a client method)
                const courses = await courseClient.listInstructorCourses(auth.id);
                setCourses(courses);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [auth.id, intl]);
*/
    /*** METHODS ***/

    /*** COMPONENTS */

    return (
        <>
            <div className={styles.instructorBackground}>
                <div className={styles.personal}>
                    <Image src={avatarImage} preview={false} />
                    <div className={styles.info}>
                        <div className={styles.name}>{auth.name}</div>
                        <div className={styles.description}>Class Category 1, Class Category 2</div>
                    </div>
                </div>
                <div className={styles.details}>
                    <div className={styles.content}>
                        <div className={styles.number}>5</div>
                        <div className={styles.students}>
                            <FormattedMessage id="instructor.students" />
                        </div>
                    </div>
                    <div className={styles.line}></div>
                    <div className={styles.content}>
                        <div className={styles.number}>5</div>
                        <div className={styles.students}>
                            <FormattedMessage id="instructor.courses" />
                        </div>
                    </div>
                    <div className={styles.line}></div>
                    <div className={styles.content}>
                        <div className={styles.number}>5</div>
                        <div className={styles.students}>
                            <FormattedMessage id="instructor.reviews" />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.myCourses}>
                <span className={styles.text}>
                    <FormattedMessage id="instructor.myCourses" />
                </span>
                <Tooltip title={<FormattedMessage id="instructor.addCourse" />}>
                    <Link to={`/course-creation`}>
                        <Button icon={<PlusOutlined />} type="ghost"></Button>
                    </Link>
                </Tooltip>
            </div>
            <List
                itemLayout="vertical"
                dataSource={courses || []}
                renderItem={(course: any) => {
                    return (
                        <List.Item key={course.id}>
                            <Link to={`/course-instructor/${course.id}`}>
                                <Card
                                    title={
                                        <div className={styles.title}>
                                            <Image src={exampleTwoImage} preview={false} className={styles.image} />
                                            <div className={styles.info}>
                                                <div className={styles.course}>{course.name}</div>
                                                <div className={styles.seats}>
                                                    <FormattedMessage id="instructor.seats" values={{ seats: course.seats }} />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    className={styles.card}
                                >
                                    <div className={styles.details}>
                                        <span className={styles.students}>
                                            <FormattedMessage id="instructor.registered" values={{ students: course.students?.length }} />
                                        </span>
                                        <span className={styles.status}>{course.status}</span>
                                    </div>
                                </Card>
                            </Link>
                        </List.Item>
                    );
                }}
            />
        </>
    );
};
