import { message } from 'antd';
import { duration } from 'moment';
import { IntlShape } from 'react-intl';

class AlertService {
    displayError = (error: any, intl: IntlShape, errorMessages: ErrorMessage[] = []) => {
        const status = error.response && error.response.status;
        const errorMessage = status && errorMessages.find((m) => m.status === status);
        if (errorMessage) {
            message.error(intl.formatMessage({ id: errorMessage.message }), duration);
        } else if (status === 403) {
            message.error(intl.formatMessage({ id: 'status.forbiddenError' }));
        } else if (status === 404) {
            message.error(intl.formatMessage({ id: 'status.notFoundError' }));
        } else {
            message.error(intl.formatMessage({ id: 'status.internalError' }));
        }
    };
}

export interface ErrorMessage {
    status: number;
    message: string;
    duration?: number;
}

const alertService = new AlertService();
export default alertService;
