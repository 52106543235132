import Icon from '@ant-design/icons';
import { Button, Carousel, Col, Image, List, Row } from 'antd';
import Search from 'antd/es/input/Search';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import courseClient from '../../clients/CourseClient';
import { useRhodesCourses } from '../../hooks/useRhodes';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../contexts/CustomContext';
import { Course, CustomAuth } from '../../models/Entities';
import calendarIcon from '../../resources/images/course-calendar-icon.svg';
import creditsIcon from '../../resources/images/course-credits-icon.svg';
import rocketIcon from '../../resources/images/course-rocket-icon.svg';
import { ReactComponent as filterIcon } from '../../resources/images/filter-icon.svg';
import exampleImage from '../../resources/images/example1.png';
import exampleTwoImage from '../../resources/images/example2.png';
import alertService from '../../services/AlertService';
import styles from './StudentPage.module.scss';

/**
 * Returns the student page.
 * @returns the student page.
 */
const StudentPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    //const [loading, setLoading] = useState<'loading'>();
    //const [courses, setCourses] = useState<Course[]>([]);
    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;
    const { courses, coursesLoading: loading, coursesError: error } = useRhodesCourses();
/*
    // initialize app
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');

                // TODO: load page data (probably calling a client method)
                const courses = await courseClient.listStudentCourses(auth.id);
                setCourses(courses);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [auth.id, intl]);
*/
    /*** METHODS ***/

    /*** VISUAL ***/

    return (
        <LayoutComponent
            title={<FormattedMessage id="home.title" />}
            menu="student"
            rightHeader={
                <Link to="/mycourses">
                    <div className={styles.myCourses}>
                        <FormattedMessage id="myCourses.title" />
                    </div>
                </Link>
            }
            subHeader={
                <Search
                    placeholder={intl.formatMessage({
                        id: 'student.whatToLearn'
                    })}
                    size="large"
                    allowClear
                    className={styles.search}
                />
            }
            lilacHeader
        >
            <h1 className={styles.featured}>
                <FormattedMessage id="student.featured" />
            </h1>
            <Carousel autoplay slidesToShow={1} autoplaySpeed={15000}>
                {courses.map((course: any) => {
                    return (
                        <Link to={`/course/${course.id}`} key={course.id}>
                            <div className={styles.featuredCard}>
                                <Image src={exampleImage} preview={false} className={styles.image} />
                                <div className={styles.title}>{course.name}</div>
                                <div className={styles.details}>
                                    <span>
                                        <Image src={creditsIcon} preview={false} /> {course.credits}
                                    </span>
                                    <span>
                                        <Image src={calendarIcon} preview={false} /> {course.status}
                                    </span>
                                    <span>
                                        <Image src={rocketIcon} preview={false} /> {course.seatsLeft} <FormattedMessage id="student.seats" />
                                    </span>
                                </div>
                            </div>
                        </Link>
                    );
                })}
            </Carousel>
            <h1 className={styles.allCourses}>
                <FormattedMessage id="student.allCourses" />
                <Button icon={<Icon component={filterIcon} />} className={styles.filter}>
                    <span>
                        <FormattedMessage id="button.filter" />
                    </span>
                </Button>
            </h1>
            <List
                grid={{
                    column: 1
                }}
                dataSource={courses}
                loading={loading}
                /* loadMore={
                    notifications.length < notificationsPage?.totalItems! ? (
                        <div className={styles.loadMore}>
                            <Button onClick={loadMore} loading={loading === 'loadingMore'}>
                                <FormattedMessage id="button.loadMore" tagName="span" />
                            </Button>
                        </div>
                    ) : (
                        <></>
                    )
                } */
                /* locale={{
                    emptyText: (
                        <div className={styles.empty}>
                            <FormattedMessage id="scheduledNotifications.notScheduledNotifications" />
                        </div>
                    ),
                }} */
                renderItem={(course: any) => {
                    return (
                        <Link to={`/course/${course.id}`} key={course.id}>
                            <List.Item className={styles.card}>
                                <Row className={styles.course}>
                                    <Col span={4}>
                                        <Image src={exampleTwoImage} preview={false} className={styles.image} />
                                    </Col>
                                    <Col span={20} className={styles.info}>
                                        <div className={styles.title}>{course.name}</div>
                                        <div className={styles.details}>
                                            <span>
                                                <Image src={creditsIcon} preview={false} /> {course.credits}
                                            </span>
                                            <span>
                                                <Image src={calendarIcon} preview={false} /> {course.status}
                                            </span>
                                            <span>
                                                <Image src={rocketIcon} preview={false} /> {course.seatsLeft} <FormattedMessage id="student.seats" />
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            </List.Item>
                        </Link>
                    );
                }}
            />
        </LayoutComponent>
    );
};
export default StudentPage;
